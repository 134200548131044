/*
*
|  Page Name       : leader.scss
|  Description     : scss for leaders page use
|  Created by      : May Altamerano
|  Date Created    : July 6 2021
|  Last Update by  : May Altamerano
|  Last update     : July 6 2021
*
*/

.--leader {
  text-align: center;

  .svg {
    position: absolute;

    &--1 {
      top: 33vw;
      left: 0;
      width: 40vw;
    }

    &--2 {
      top: 22vw;
      right: 0;
      width: 17vw;
    }
  }
}
