/*
*
|  Page Name       : career.scss
|  Description     : scss for contact us page use
|  Created by      : Michael Dela Rosa
|  Date Created    : Oct 28, 2022
|  Last Update by  : Michael Dela Rosa
|  Last update     : Oct 28, 2022
*
*/

.--career {
  position: relative;
  overflow-x: hidden;

  .svg {
    position: absolute;
    display: block;
    z-index: 9;

    &--1 {
      top: -1%;
      right: -10%;
      width: 36vw;
      height: 40vw;
    }

    &--2 {
      top: -9%;
      left: -40%;
      width: 60vw;
      height: 60vw;
    }

    &--3 {
      top: 13%;
      left: -60%;
      width: 70vw;
      height: 70vw;
    }

    &--4 {
      top: 27%;
      right: -3.5%;
      width: 17vw;
      height: 25vw;
    }

    &--5 {
      bottom: -2%;
      right: -5%;
      width: 40vw;
      height: 40vw;
    }

    &--6 {
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 100vw;
    }
  }

  .career-choosefile-button {
    border-radius: 0;
    background-color: white;
    border: 1px solid #676ab1;
    color: #283a97;
    width: 100%;
    font-family: "gill sans", sans-serif !important;
    letter-spacing: 0.2vw;
    height: 3rem;
  } 
  .career-choosefile-text {
    font-size: 2.82vw;
    margin-left: 0;
    letter-spacing: 0.18em;
    color: #283a97;
    white-space: nowrap;
    text-align: center;
  }

  .career-choosefile-button:hover {
    border-radius: 0;
    background-color: white;
    border: 1px solid #676ab1;
    color: #283a97;
  }
  .career-form-textarea {
    border: 1px solid #676ab1;
    height: 10vw;
    border-radius: 0;
  }
  .land-label {
    font-size: 15px;
    // margin-top: 1vh;
  }

  .land-form {
    border: 1px solid #676ab1;
    height: 3.5vw;

    &:focus {
      outline-color: #283a97;
      outline-width: 2px;
      outline-style: solid;
    }
  }

  .land-supporting {
    font-size: 10pt;
  }

  .land-remarks {
    height: 30vh;
  }

  .land-radio {
    display: table;
  }

  .land-check {
    font-size: 8pt;
  }

  .land-submit {
    width: 60%;
    height: 100%;
    padding-top: 2vh;
    padding-bottom: 2vh;
    border: 1px solid #283a97;
    border-radius: 0;
    background-color: #283a97;
    color: white;
    font-family: "gill sans", "regular" !important;
    letter-spacing: 0.2em;
    font-size: 0.9rem;

    &__container {
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }
  }

  .land-upload {
    width: 100%;
    height: 100%;
    border: 1px solid #676ab1;
    border-radius: 0;
    background-color: white;
    color: #283a97;
  }

  .land-formgroup {
    margin-top: 3vh;
  }

  .--footer {
    border-top: 1px solid #283a97;
  }

  .text-header {
    font-size: 16pt;
    font-family: "gill sans medium", "regular" !important;
    letter-spacing: 0.2em;
    color: #676ab1;
    text-align: center;
    padding-bottom: 10vw;

    &__desc {
      margin-bottom: 8vw !important;
    }
  }

  .--bg-1 {
    // background: url("../images/career/Group 851.png") top center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 10vw 0 0 0;
    min-height: 50vw;
    width: 100%;
    background-color: white;

    .--max {
      max-width: 86%;
      // img {
      //   margin: 0 auto 3vw auto;
      //   display: block;
      //   height: 8vw;
      //   width: 8vw;
      //   object-fit: contain;
      //   image-rendering: pixelated;
      // }
    }

    h1.--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.4vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 1;
      font-size: 2vw;
      color: #283a97;
      margin: 4vw 0 4vw 0;
      text-align: center;

      .lineParent {
        overflow: visible;
      }
    }

    h3.--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.2vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 1.1;
      font-size: 1.19vw;
      color: #283a97;
      margin: 0 0 0.5vw 0;
      text-align: center;
    }

    p.--desc {
      font-family: "gill sans medium", sans-serif;
      font-weight: 500 !important;
      margin-bottom: 2.4vw;
      font-size: 1.2vw;
      color: #283a97;
      line-height: 1.4vw;
      text-align: center;
      letter-spacing: 0.17vw;
    }

    .header-shapes {
      position: absolute;
      height: 100vw;
      width: 100%;
      top: 10vw;
      overflow: hidden;

      &::before {
        content: "";
        height: 7.8vw;
        width: 57.5vw;
        background-color: transparent;
        border: 1px solid #676ab1;
        position: absolute;
        top: -2vw;
        left: -42vw;
        transition: height 0.3s;
        transition: width 0.3s;
        transform: skew(321deg, 16.5deg);
      }

      &::after {
        content: "";
        height: 11.8vw;
        width: 57.5vw;
        background-color: transparent;
        border: 1px solid #676ab1;
        position: absolute;
        top: 40vw;
        right: -44vw;
        transition: height 0.3s;
        transition: width 0.3s;
        transform: skew(321deg, 16.5deg);
      }
    }
  }

  .--bg-2 {
    padding: 1 0 0 0;
    min-height: 80vw;
    width: 100%;
    position: relative;

    .--max {
      max-width: 100%;
      min-height: 42vw;
      z-index: 2;
      position: relative;
    }

    h3.--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.2vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 1.1;
      font-size: 1.05vw;
      color: #ffffff;
      margin: 0 0 3.5vw 0;
      text-align: center;
    }

    form {
      margin-left: 5vw;

      .form-group {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
      }

      label {
        font-family: "gill sans", sans-serif !important;
        letter-spacing: 0.1vw;
        font-weight: 600 !important;
        text-transform: uppercase;
        line-height: 1.1;
        font-size: 0.8vw;
        color: #ffffff;
        margin: 0 0.5vw 0.5vw 0;
        text-align: right;
      }

      input {
        color: #ffffff;
        border: 1px solid #ffffff !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        outline: 0 !important;
        height: 4vw;
        padding: 1vw;
        background-color: transparent !important;
        background: transparent !important;
        font-family: "gill sans medium", sans-serif !important;
        letter-spacing: 0.1vw;
      }

      textarea {
        color: #ffffff;
        border: 1px solid #ffffff !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        outline: 0 !important;
        height: 4vw;
        padding: 1vw;
        background-color: transparent !important;
        background: transparent !important;
        resize: none;
        font-family: "gill sans medium", sans-serif !important;
        letter-spacing: 0.1vw;
      }

      .--btn-send {
        // background: url("../images/contactus/Contact_Button-01.png") top center;
        background-repeat: no-repeat;
        transition: background 0.3s;
        image-rendering: -webkit-optimize-contrast;
        width: 15vw;
        height: 60px;
        background-position: center;
        background-size: contain;
        border: 0 !important;
        border-radius: 0 !important;
        outline: 0 !important;
        display: block;
        margin: 5vw auto 0 11.7vw;
        outline: 0 !important;
        box-shadow: none !important;

        &:focus {
          outline: 0 !important;
        }
      }
    }

    .--map-container {
      width: 35vw;
      height: 30vw;
      padding-top: 2vw;
      position: relative;

      iframe {
        margin-left: -2.5vw;
        width: 37.5vw;
      }
    }

    .header-shapes {
      position: absolute;
      height: 100vw;
      width: 100%;
      top: 3vw;

      overflow: hidden;

      &::before {
        content: "";
        height: 10.8vw;
        width: 57.5vw;
        background-color: transparent;
        border: 1px solid #ffffff;
        position: absolute;
        top: -2vw;
        left: -42vw;
        transition: height 0.3s;
        transition: width 0.3s;
        transform: skew(321deg, 16.5deg);
      }

      &::after {
        content: "";
        height: 11.8vw;
        width: 67.5vw;
        background-color: #9c9eca8f;
        position: absolute;
        top: 12vw;
        right: -27vw;
        transition: height 0.3s;
        transition: width 0.3s;
        transform: skew(318deg, 15deg);
      }
    }
  }

  .header-bg {
    background: white;
    background-size: 105vw auto !important;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;
    position: absolute;
    height: 80vw;
    width: 100%;
    top: 0;
    z-index: -3;
  }

  .form-bg {
    position: absolute;
    top: -5vw;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: -1;

    &::before {
      content: "";
      border-top: 27vw solid transparent;
      border-left: 100vw solid #283a97;
      height: 100% !important;
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media (max-width: 1024px) {
    background: url("../images/contactus/Contact_BG-01.png") top -8vw right;
    background-size: 155vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;

    .header-bg {
      display: none;
    }


    .form-bg {
      background-size: 193vw 194vw !important;
      background-repeat: no-repeat;
      background-attachment: scroll;
      transition: background 0.3s;
      image-rendering: -webkit-optimize-contrast;
      position: absolute;
      height: 170vw;
      width: 100%;
      // top: -20vw;
      z-index: 0;
    }

    .--bg-1 {
      padding: 200px 0 0 0;

      .--max {
        max-width: 90%;
      }

      h1.--title {
        font-size:5vw;
      }

      .text-header__desc {
        font-size: 2vw;
        text-transform: uppercase;
        line-height: 2;
        letter-spacing: 0.4px;
        margin-top: 7vw;
      }

      // img {
      //   height: 25vw;
      //   width: 25vw;
      // }

      h3 {
        &.--title {
          font-size: 0.875em;
        }
      }

      p {
        &.--desc {
          font-size: 0.875em;
          line-height: 1.4;
        }
      }

      .row {
        &:nth-child(2) {
          .col-md-4 {
            width: 50%;
            max-width: 50%;

            &:last-child {
              width: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }

    .--bg-2 {
      padding: 30.5vw 0 0 0;

      .--max {
        z-index: 2;
        position: relative;
      }

      .col-md-6 {
        width: 100%;
        max-width: 100%;
      }

      h3 {
        &.--title {
          font-size: 1.5em;
          margin: 0 0 6.5vw 0;
        }
      }

      form {
        label {
          font-size: 0.875em;
        }

        input {
          height: 60px;
        }

        textarea {
          height: 60px;
        }

        .--btn-send {
          width: 215px;
          height: 45px;
          margin: 5vw auto 0 23.7vw;
        }
      }

      .--map-container {
        width: 100%;
        height: 50vw;
        padding-top: 2vw;
        position: relative;
        display: block;
        margin: 100px auto;

        iframe {
          margin-left: 0;
          width: 100%;
        }
      }
    }

    .career-choosefile-text {
      font-size: 1.5vw;
      margin-left: 0;
      letter-spacing: 0.18em;
      color: #283a97;
      white-space: nowrap;
      text-align: center;
    }
  }

  @media (max-width: 920px) {
    background: url("../images/contactus/Contact_BG-01.png") top -8vw right;
    background-size: 165vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;
  }

  @media (max-width: 740px) {
    background: url("../images/contactus/Contact_BG-01.png") top -8vw right;
    background-size: 180vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;

    .form-bg {
      height: 195vw;
    }
  }

  @media (max-width: 600px) {
    background: url("../images/contactus/Contact_BG-01.png") top -8vw right;
    background-size: 200vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;

    .--bg-1 {
      padding: 150px 0 100px 0;

      .header-shapes {
        &::before {
          top: 13vw;
        }
      }
    }

    .career-choosefile-text {
      font-size: 2.5vw;
      margin-left: 0;
      letter-spacing: 0.18em;
      color: #283a97;
      white-space: nowrap;
      text-align: center;
    }
  }

  @media (max-width: 566px) {
    background: url("../images/contactus/Contact_BG-01.png") top -8vw right;
    background-size: 220vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;

    .form-bg {
      background-size: 350vw 370vw !important;
      height: 320vw !important;
      z-index: 0;
      bottom: 0;
      // top: -44vw;
    }

    .--bg-2 {
      padding: 45.5vw 0 0 0;

      form {
        margin-left: 0;
        width: 90%;
        margin: 0 auto;

        label {
          text-align: left !important;
        }
      }
    }
  }

  @media (max-width: 500px) {
    background: url("../images/contactus/Contact_BG-01.png") top -8vw right;
    background-size: 235vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;

    .--bg-2 {
      padding: 45.5vw 0 0 0;

      form {
        margin-left: 0;
        width: 90%;
        margin: 0 auto;

        label {
          text-align: left !important;
        }
      }
    }

    .--bg-1 {
      max-width: 100%;

      h1.--title {
        font-size: 20px !important;
        letter-spacing: 4px;
      }

      .text-header__desc {
        font-size: 12px;
        text-transform: uppercase;
        line-height: 2;
        letter-spacing: 0.4px;
        margin-top: 7vw;
      }
    }
  }

  @media (max-width: 420px) {
    background: url("../images/contactus/Contact_BG-01.png") top -8vw right;
    background-size: 280vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;

    .--bg-2 {
      padding: 45.5vw 0 0 0;

      form {
        margin-left: 0;
        width: 90%;
        margin: 0 auto;

        label {
          text-align: left !important;
        }

        .--btn-send {
          margin: 5vw auto 0 auto;
        }
      }
    }

    .--bg-1 {
      max-width: 100%;

      h1.--title {
        font-size: 20px !important;
        letter-spacing: 4px;
      }

      .text-header__desc {
        font-size: 12px;
        text-transform: uppercase;
        line-height: 2;
        letter-spacing: 0.4px;
        margin-top: 7vw;
      }
    }
  }

  @media (max-width: 400px) {
    background: url("../images/contactus/Contact_BG-01.png") top -8vw right;
    background-size: 280vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;

    .--bg-2 {
      padding: 45.5vw 0 0 0;

      form {
        margin-left: 0;
        width: 90%;
        margin: 0 auto;

        label {
          text-align: left !important;
        }

        .--btn-send {
          margin: 5vw auto 0 auto;
        }
      }
    }

    .--bg-1 {
      max-width: 100%;

      h1.--title {
        font-size: 20px !important;
        letter-spacing: 4px;
      }

      .text-header__desc {
        font-size: 3vw;
        text-transform: uppercase;
        line-height: 2;
        letter-spacing: 0.4px;
        margin-top: 7vw;
      }
    }
  }
}
