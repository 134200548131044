/*
*
|  Page Name       : home.scss
|  Description     : scss for home page use
|  Created by      : May Altamerano
|  Date Created    : June 25 2021
|  Last Update by  : May Altamerano
|  Last update     : June 25 2021
*
*/

@keyframes load {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slidein {
  0% {
    left: 30%;
    opacity: 0;
  }

  100% {
    left: 50%;
    opacity: 1;
  }
}

@keyframes revealbg {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.lineParent {
  overflow: hidden;
}
.lineChildren {
  transform: translate(0, 500px);
}

.--font-normal {
  font-family: "Gill Sans";
}
.--home {
  min-height: 100vh; 
  width: 100%;
  position: relative;
  overflow-x: hidden;

  .carousel-indicators {
    width: 100% !important;
    height: 30px;
    bottom: -1vw;
    display: flex;
    text-align: center;
    margin: 0 !important;
    padding-top: 15vh;
    z-index: -1;
    transform: translateX(-2%);
    li {
      height: 20px;
      width: 20px;
      border-radius: 100px;
      border: 1px solid #ffffff;
      background-color: transparent;
      transition: background-color 0.5s;
      margin: 4vw 0 0 3vw;
      cursor: pointer;

      &.active {
        transition: background-color 0.5s;
        background-color: #ffffff;
      }
    }
  }

  .--header {
    position: relative;
    width: 100%;
    transition: width 0.5s;
    transition: height 0.5s;
    background-attachment: relative;
    padding-top: 7vw;
    display: flex;
    align-items: flex-start;
    min-height: 67vh !important;

    .load {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      min-height: 80vh;

      display: grid;
      place-content: center;

      animation-fill-mode: both;
      animation-delay: 1s;
      animation-duration: 3s;
      animation-name: load;
      animation-iteration-count: 1;
    }

    .hero {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: 130vh;
      z-index: -1;

      display: grid;
      place-content: center;

      background: white;
      width: 100vw;
      position: absolute;

      & > * {
        grid-row: 1/2;
        grid-column: 1/2;
      }

      &-bg {
        width: 100%;
        opacity: 1;

        animation-fill-mode: backwards;
        animation-delay: 4s;
        animation-duration: 2s;
        animation-name: slidein;
        animation-iteration-count: 1;
      }

      h1 {
        position: absolute;
        left: 50.2%;
        top: 35%;
        transform: translate(-50%, -100%);

        font-family: "gill sans", sans-serif !important;
        text-transform: uppercase;
        line-height: 1.5em;
        letter-spacing: 0.2em;
        font-size: 2vw;
        color: #283a97;
        text-align: center;

        animation-fill-mode: backwards;
        animation-delay: 4s;
        animation-duration: 2s;
        animation-name: slidein;
        animation-iteration-count: 1;
      }
    }

    .--home-temokin-img {
      width: 25vw;
      margin-top: 18vh;
    }
    .hero-container {
      width: 100%;
      position: absolute;
      z-index: -5;
      height: 80vw;
      top: 0;
    }
    .--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.5vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 4.7vw;
      font-size: 2.5vw;
      color: #283a97;
      margin: 13vw auto 0 auto;
      text-align: center;
    }
  }
  .--bg-2 {
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100vw;
    background-color: transparent;
    width: 100%;
    min-height: 67vw;
    z-index: 2;
    /* &::after {
      content: "";
      position: absolute;
      bottom: -14%;
      right: 0;
      left: 0;
      width: 100%;
      //   background-color: #283a97;
      background: url("../images/aboutus/About_BG-08.svg");
      background-size: 100%;
      background-position: contain;
      background-repeat: no-repeat;
      height: 30vw;
    } */
    .hero-container {
      width: 120vw;
      position: absolute;
      z-index: -5;
      height: 80vw;
      top: -9vw;
      left: -9vw;
    }
    .--container-2 {
      padding-top: 15vw;
      padding-bottom: 14vw;
      min-height: 50vw;
      position: relative;
      z-index: 5;
      &.--max {
        max-width: 90%;
      }
      .--title {
        font-family: "gill sans", sans-serif !important;
        letter-spacing: 0.6vw;
        font-weight: 600 !important;
        text-transform: uppercase;
        line-height: 3vw;
        font-size: 2.6vw;
        color: #ffffff;
        margin: 7.6vw 0 5vw 0;
        text-align: center;
      }
      .--carousel {
        .carousel-item {
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          align-items: center;

          img {
            padding-left: 3.5vw;
            height: 33vw;
            object-fit: cover;
            object-position: 0 0;
            width: 50% !important;
            background-color: #283a97;
            // clip-path: inset(0% 0% 0% 100%);
            transition: clip-path 1s cubic-bezier(0.77, 0, 0.175, 1);
            clip-path: inset(0% 0% 0% 0%);
          }
          .carousel-caption {
            position: relative;
            right: unset;
            left: 10%;
            bottom: unset;
            color: white !important;
            margin-right: 10%;
            padding: 0 2.3vw 0 0;
            text-align: left;
            width: 28% !important;
            transform: scale(1) !important;
            transition-delay: 0.3s;

            h3 {
              font-family: "gill sans medium", sans-serif !important;
              letter-spacing: 0.4vw;
              font-size: 1.62vw;
              font-weight: 600;
              margin-bottom: 25px;
            }
            p {
              font-family: "gill sans", sans-serif !important;
              font-size: 0.85vw;
              min-height: 19vw;
              font-weight: 400 !important;
              line-height: 1.9;
              letter-spacing: 0.04vw;
            }
            .--btn-check-our-work-1 {
              font-family: "gill sans", sans-serif !important;
              letter-spacing: 0.2em;
              font-size: 0.7vw;
              color: #283a97;
              font-weight: 600;
              padding: 1.3vw 1.5vw;
              border-radius: 0;
              position: absolute;
              text-align: center;
              top: 14vw;
              left: 0;
              outline: 0 !important;
              box-shadow: none !important;
              background-color: #ffffff !important;
              text-decoration: none !important;
            }
            .--btn-check-our-work-2 {
              font-family: "gill sans", sans-serif !important;
              letter-spacing: 0.2em;
              font-size: 0.7vw;
              color: #283a97;
              font-weight: 600;
              padding: 1.3vw 1.5vw;
              border-radius: 0;
              position: absolute;
              text-align: center;
              top: 19vw;
              left: 0;
              outline: 0 !important;
              box-shadow: none !important;
              background-color: #ffffff !important;
              text-decoration: none !important;
            }
            .--btn-check-our-work-1:hover {
              background-color: #283a97 !important;
              border: 1px solid white;
              color: white;
            }
            .--btn-check-our-work-2:hover {
              background-color: #283a97 !important;
              border: 1px solid white;
              color: white;
            }
          }
        }
        /* .carousel-indicators {
          width: 37.5% !important;
          height: 30px;
          margin-left: -8vw;
          margin-right: 0;
          bottom: -1vw;
          display: flex;
          justify-content: flex-end;
          padding-right: 5.25vw;
          li {
            height: 25px;
            width: 25px;
            border-radius: 100px;
            border: 1px solid #ffffff;
            background-color: transparent;
            transition: background-color 0.5s;
            margin: 0 0 0 3vw;
            cursor: pointer;
            &.active {
              transition: background-color 0.5s;
              background-color: #ffffff;
            }
          }
        } */
        .carousel-control-prev,
        .carousel-control-next {
          display: none;
        }
      }
    }
    .--carousel-mb {
      .carousel-item {
        height: 110vw;
      }
    }
  }
  .--bg-3 {
    position: relative;

    /* &::before {
      content: "";
      position: absolute;

      background-image: url("../images/home/Page3_BG.jpg");
      background-size: contain;
      background-position: center bottom;
      background-repeat: no-repeat;

      width: 100%;
      height: 120%;
      bottom: 0;
      left: 0;
    } */

    .hero-container {
      width: 100vw;
      position: absolute;
      z-index: -5;
      bottom: 0;
      height: 55vw;
      overflow: hidden;
    }
  }
  .--container-3 {
    padding: 10vw 0 8vw 0;

    &.--max {
      max-width: 93%;
    }
    .--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.3vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 3vw;
      font-size: 2.2vw;
      color: #283a97;
      margin: 0 0 8vw 0;
      text-align: center;
    }
    img {
      display: block;
      width: 13vw;
      height: 13vw;
      object-fit: contain;
      object-position: center;
      image-rendering: -webkit-optimize-contrast;

      &.--center {
        margin: 0 auto 50px auto;
      }
    }
    h5 {
      &.--project-title {
        font-family: "gill sans", sans-serif !important;

        letter-spacing: 0.15em;
        font-size: 0.5vw;
        font-weight: 600;
        margin-bottom: 1vw;
        text-align: center;
        color: #283a97;
        line-height: 1em;
      }
    }
    p {
      &.--desc {
        color: #676ab1;
        font-family: "gill sans medium", sans-serif !important;

        font-size: 1.1vw;
        font-weight: 400;
        letter-spacing: 0.02vw;
        text-align: center;
        margin-bottom: 3.5vw;
      }
    }
  }
  .--bg-4 {
    .hero-container {
      width: 105vw;
      position: absolute;
      z-index: -9;
      min-height: 69vw;
      top: 0vw;
    }
  }
  .--container-4 {
    padding: 10vw 12vw 5vw 12vw;
    position: relative;

    /* &::before {
      content: "";
      position: absolute;

      background-image: url("../images/home/About_BG-10.svg");
      background-size: contain;
      background-position: top left;
      background-repeat: no-repeat;

      width: 100%;
      top: 0;
      left: 0;
      height: 20vw;
    }

    &::after {
      content: "";
      position: absolute;

      background-image: url("../images/home/About_BG-09.svg");
      background-size: contain;
      background-position: bottom right;
      background-repeat: no-repeat;

      width: 100%;
      bottom: 0;
      right: 0;
      height: 20vw;
    } */
    &.--max {
      max-width: unset;
    }
    .--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.5vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 3vw;
      font-size: 2.4vw;
      color: #283a97;
      margin: 1.5vw 0 6.5vw 0;
      text-align: center;
    }
    img {
      &.--img {
        width: 40vw;
        object-fit: contain;
        object-position: 0 0;
        height: 28.5vw;
      }
    }
    .--launches-details {
      .--desc-mobile {
        display: none;
      }
      height: 25.5vw;
      padding-left: 5vw;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      img {
        &.--img-logo {
          width: 15vw;
          object-fit: contain;
          object-position: 0 0;
          image-rendering: pixelated;
          height: 4vw;
          display: block;
          margin-bottom: 2vw;
        }
      }
      p {
        font-family: "gill sans medium", sans-serif !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1.3vw;
        line-height: 2;
        color: #283997;
        margin-bottom: 0;
        letter-spacing: 0.15vw;
        &.--txt {
          font-weight: 600;
          &:nth-child(3) {
            margin-bottom: 6vw;
          }
        }
        &.--desc {
          font-size: 0.9vw !important;
          line-height: 2;
          letter-spacing: 0;
        }
      }
    }
    .--btn-im-interested {
      font-family: "gill sans medium", sans-serif !important;
      letter-spacing: 0.3vw;
      font-size: 1.1vw;
      background-color: #283a97;
      color: #ffffff;
      font-weight: 500;
      padding: 20px 4vw;
      border-radius: 0;
      outline: 0 !important;
      box-shadow: none !important;
      display: block;
      margin: 9vw auto 0 auto;
      border: 0 !important;
      box-shadow: none !important;
      width: fit-content;
      text-decoration: none;
    }
  }
  .header-bg {
    width: 100%;
    position: relative;
    z-index: -5;
    height: 80vw;
    top: 0;
  }
  .overlay-blue {
    position: absolute;
    height: 13vw;
    width: 50vw;
    top: -12vw;
    &::before {
      content: "";
      height: 13vw;
      width: 50vw;
      background-color: #101869;
      opacity: 0.6;
      position: absolute;
      top: 2vw;
      left: -13vw;
      transition: height 0.3s;
      transition: width 0.3s;
      transform: skew(328deg, 17deg);
    }
  }
  .overlay-white {
    position: absolute;
    height: 13vw;
    width: 50vw;
    top: -1.5vw;

    &::before {
      content: "";
      height: 7.8vw;
      width: 57.5vw;
      background-color: transparent;
      border: 1px solid #ffffff;
      position: absolute;
      top: 0;
      left: 11vw;
      transition: height 0.3s;
      transition: width 0.3s;
      transform: skew(321deg, 16.5deg);
    }
  }
  .overlay-white2 {
    position: absolute;
    height: 13vw;
    width: 50vw;
    top: -1.5vw;

    &::before {
      content: "";
      height: 7.8vw;
      width: 57.5vw;
      background-color: transparent;
      border: 1px solid #ffffff;
      position: absolute;
      top: 0;
      left: 11vw;
      transition: height 0.3s;
      transition: width 0.3s;
      transform: skew(321deg, 16.5deg);
    }
  }
  .featured-bg {
    width: 120vw;
    position: relative;
    z-index: -5;
    height: 80vw;
    top: -9vw;
    left: -9vw;
  }
  .projects-bg {
    width: 100vw;
    position: relative;
    z-index: -5;
    bottom: 0;
    height: 60vw;
  }
  .launches-bg {
    width: 105vw;
    position: relative;
    z-index: -9;
    min-height: 69vw;
    top: -2vw;
  }
  @media (max-width: 1200px) {
    overflow: hidden;
    .header-bg {
      top: -5vw;
      transition: top 0.5s;
    }
    .--bg-4 {
      .hero-container {
        display: none;
      }
    }
    .--bg-3 {
      .hero-container {
        display: none;
      }
    }
  }

  @media (max-width: 992px) {
    margin-top: 0;
    transition: margin-top 0.5s;
    image-rendering: pixelated;

    // .hero-bg {
    //   width: 550vw !important;
    //   margin-right: 440vw;
    //   margin-top: 0;
    // }

    .overlay-white2 {
      height: 24vw;
      top: 95%;
      left: -40vw;

      &::before {
        left: 20vw;
        height: 11.8vw;
      }
    }

    .header-bg {
      height: 90vw;
      transition: height 0.5s;
    }

    .--home-temokin-img {
      width: 45vw !important;
    }
    .--header {
      min-height: 67vh !important;
      padding: 20vw 0 10vw 0;
      .--title {
        font-size: 1.5em;
        line-height: 1.6;
        margin: 0;
      }
    }

    .--bg-2 {
      &::after {
        height: 65vw !important;
      }
      .--title {
        font-size: 4vw;
        line-height: 1.6;
        letter-spacing: 0.2em;
      }
      .--carousel-container {
        position: relative;
      }
      .--btn-carousel-container {
        position: absolute;
        width: 100%;
        top: 0;
        z-index: 10;
      }
      button {
        &.carousel-control-prev {
          background: url("../images/mobile/home/Obj_Arrow-02.png") center;
          transition: background 0.3s;
          background-attachment: local;
          background-repeat: no-repeat;
          background-size: contain;
          width: 9vw;
          display: none;
          padding: 0;
          cursor: pointer;
          height: 35vw;
          opacity: 1;
          outline: 0 !important;
          box-shadow: none !important;
          border: 0 !important;
          span {
            display: none !important;
          }
        }
        &.carousel-control-next {
          background: url("../images/mobile/home/Obj_Arrow-01.png") center;
          transition: background 0.3s;
          background-attachment: local;
          background-repeat: no-repeat;
          background-size: contain;
          width: 9vw;
          display: none;
          padding: 0;
          cursor: pointer;
          height: 35vw;
          opacity: 1;
          outline: 0 !important;
          box-shadow: none !important;
          border: 0 !important;
          span {
            display: none !important;
          }
        }
      }
      .--carousel-mb {
        .carousel-inner {
          img {
            width: 63vw !important;
            margin: 0 auto;
          }
          .carousel-caption {
            position: relative;
            right: unset;
            left: unset;
            white-space: break-spaces;
            margin-top: 10vw;
            h3 {
              font-size: 3vw;
              margin-bottom: 4.5vw;
              letter-spacing: 0.7vw;
              font-family: "gill sans", sans-serif !important;
            }
            p {
              font-size: 2.3vw;
              letter-spacing: 0.3vw !important;
              font-family: "gill sans medium", sans-serif;
              br {
                display: none;
              }
            }
          }
        }
        .carousel-item {
          &--1 {
            height: 95vw;
            opacity: 0;
            transform: 0;
            display: block !important;

            transition: all 0.5s ease-in-out;

            &.true {
              display: block !important;

              opacity: 1;
              transition: all 0.5s ease-in-out 0.2s;

              // height: 100%;
            }
          }
        }
        a.carousel-control-next,
        a.carousel-control-prev {
          display: none;
          span {
            display: none !important;
          }
        }
        .--btn-check-our-work {
          font-family: "gill sans", sans-serif !important;
          letter-spacing: 0.3vw;
          font-size: 0.875em;
          color: #283a97;
          font-weight: 400;
          padding: 13px 20px;
          border-radius: 0;
          outline: 0 !important;
          box-shadow: none !important;
          margin: 30px auto 0 auto;
          background-color: #ffffff !important;
          text-decoration: none !important;
          display: block;
          max-width: 250px;
        }
      }
    }
    .--container-3 {
      padding: 10vw 0 6vw;
      .--title {
        font-size: 4.5vw;
        line-height: 1.6;
        letter-spacing: 0.2em;
      }
    }
    .--container-4 {
      position: relative;
      padding: 100px 0;
      position: relative;
      z-index: 1;
      &.--max {
        max-width: 100% !important;
        overflow: hidden;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -2%;
        bottom: 0;
        right: 0;
        background: url("../images/home/Home_BG-04.png") top left;
        background-size: 205% 100%;
        background-repeat: no-repeat;
        background-attachment: scroll;
        padding: 100px 0;
        z-index: -1;
      }
      .--title {
        font-size: 4.5vw !important;
        letter-spacing: 0.2em;
        line-height: 1.6;
        margin: 20vw 0 10vw 0;
      }
      .row {
        .col {
          flex: 1 0 100%;
        }
      }
      img {
        &.--img {
          width: 90vw;
          object-fit: contain;
          object-position: 0 0;
          height: unset;
          margin: 0 auto;
          display: block;
        }
      }
      .--launches-details {
        padding: 5vw 0 0 0;
        height: unset;
        width: 70vw;
        margin: 0 auto;
        display: grid;
        grid-template-areas:
          "myImg mytxtOne"
          "myImg mytxtTwo"
          "myDesc myDesc";
        grid-template-columns: 26vw 1fr;
        img {
          &.--img-logo {
            grid-area: myImg;
            height: auto;
            width: 25vw !important;
            margin-right: 25px;
            // margin-left: 20vw;
          }
        }
        p {
          &.--txt {
            grid-area: mytxtOne;
            font-size: 3vw !important;
            &:nth-child(3) {
              grid-area: mytxtTwo;
            }
          }
          &.--desc {
            font-size: 3vw !important;
            text-align: center;
            margin: 0 auto;
            grid-area: myDesc;
            br {
              display: block;
            }
          }
        }
      }
      .--btn-im-interested {
        display: none;

        font-size: 0.875em !important;
        padding: 15px 4vw;
      }
    }
  }

  @media (min-width: 720px) {
    margin-top: 0;
    transition: margin-top 0.5s;
    image-rendering: pixelated;
    .header-bg {
      height: 120vw;
      transition: height 0.5s;
    }
    .--header {
      min-height: 73vh !important;
      padding: 35vw 0 10vw 0;

      .load {
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        min-height: 55vh;

        display: grid;
        place-content: center;

        animation-fill-mode: both;
        animation-delay: 1s;
        animation-duration: 3s;
        animation-name: load;
        animation-iteration-count: 1;
      }

      .hero-container {
        height: 100vw;
        transition: height 0.5s;
      }
      .--title {
        font-size: 1.5em !important;
        line-height: 1.6;
        margin: 0;
      }
      .hero {
        top: 0;

        &-bg {
          width: 100%;
          opacity: 1;

          animation-fill-mode: backwards;
          animation-delay: 4s;
          animation-duration: 2s;
          animation-name: slidein;
          animation-iteration-count: 1;
        }

        h1 {
          position: absolute;
          left: 50.2%;
          top: 0%;
          transform: translate(-50%, -100%);

          font-family: "gill sans", sans-serif !important;
          text-transform: uppercase;
          line-height: 1.5em;
          letter-spacing: 0.2em;
          font-size: 5vw;
          color: #283a97;
          text-align: center;

          animation-fill-mode: backwards;
          animation-delay: 4s;
          animation-duration: 2s;
          animation-name: slidein;
          animation-iteration-count: 1;
        }
      }
    }
    .--bg-2 {
      height: 150vw;

      .hero-container {
        width: 120vw;
        position: absolute;
        z-index: -5;
        height: 160vw;
        top: -10vw;
        left: -9vw;

        .featured-bg {
          width: 290vw;
          position: relative;
          z-index: -5;
          height: 170vw;
          top: -5vw;
          left: -9vw;
        }
      }
    }

    .--btn-check-our-work-1 {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.3vw;
      font-size: 0.875em;
      color: #283a97;
      // font-weight: 600;
      padding: 2vw 1.5vw;
      border-radius: 0;
      width: 50% !important;
      margin: 0 auto;
      text-align: center;
      outline: 0 !important;
      display: block;
      box-shadow: none !important;
      background-color: #ffffff !important;
      text-decoration: none !important;
    }

    .--btn-check-our-work-2 {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.3vw;
      font-size: 0.875em;
      color: #283a97;
      // font-weight: 600;
      padding: 2vw 1.5vw;
      border-radius: 0;
      margin: 1vw auto;
      text-align: center;
      width: 50% !important;
      outline: 0 !important;
      display: block;
      box-shadow: none !important;
      background-color: #ffffff !important;
      text-decoration: none !important;
    }

    .--btn-check-our-work-1:hover {
      background-color: #283a97 !important;
      border: 1px solid white;
      color: white;
    }

    .--btn-check-our-work-2:hover {
      background-color: #283a97 !important;
      border: 1px solid white;
      color: white;
    }

    //   &::after {
    //     height: 100vh !important;
    //   }
    //   .--container-2 {
    //     padding-top: 25vw;
    //     padding-bottom: 100px;
    //     .--title {
    //       font-size: 1.2em;
    //       line-height: 1.6;
    //       margin: 7vw 0 10vw 0;
    //     }
    //   }
    // }
    .--container-3 {
      text-align: center;
      position: relative;
      &::before {
        background: url("../images/mobile/home/Page3_BG.png");

        content: "";
        position: absolute;
        top: -24vw;
        left: -57vw;
        bottom: 0;
        right: 0;
        background-size: auto 200vw;
        background-repeat: no-repeat;
        background-position: -7vw 5vw;
        background-attachment: scroll;
        // padding: 100px 0;
        z-index: -1;
      }
      &.--max {
        max-width: 100% !important;
        overflow: hidden;
      }
      .--title {
        margin-bottom: 8vw;
        margin-top: 2vw;
      }
      img {
        width: 28vw;
        height: 28vw;
        margin-bottom: 3vw !important;
        transform: inherit !important;
      }
      h5 {
        &.--project-title {
          font-size: 3vw;
          clip-path: none;
        }
      }
      p {
        &.--desc {
          font-size: 3vw;
          // margin-bottom: 50px !important;
          clip-path: none;
        }
      }
    }
  }

  @media (max-width: 700px) {
    margin-top: 0;
    transition: margin-top 0.5s;
    image-rendering: pixelated;
    .header-bg {
      height: 120vw;
      transition: height 0.5s;
    }
    .--header {
      min-height: 73vh !important;
      padding: 35vw 0 10vw 0;

      .load {
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        min-height: 55vh;

        display: grid;
        place-content: center;

        animation-fill-mode: both;
        animation-delay: 1s;
        animation-duration: 3s;
        animation-name: load;
        animation-iteration-count: 1;
      }

      .hero-container {
        height: 100vw;
        transition: height 0.5s;
      }
      .--title {
        font-size: 1.5em !important;
        line-height: 1.6;
        margin: 0;
      }
      .hero {
        top: 0;

        &-bg {
          width: 100%;
          opacity: 1;

          animation-fill-mode: backwards;
          animation-delay: 4s;
          animation-duration: 2s;
          animation-name: slidein;
          animation-iteration-count: 1;
        }

        h1 {
          position: absolute;
          left: 50.2%;
          top: 0%;
          transform: translate(-50%, -100%);

          font-family: "gill sans", sans-serif !important;
          text-transform: uppercase;
          line-height: 1.5em;
          letter-spacing: 0.2em;
          font-size: 5vw;
          color: #283a97;
          text-align: center;

          animation-fill-mode: backwards;
          animation-delay: 4s;
          animation-duration: 2s;
          animation-name: slidein;
          animation-iteration-count: 1;
        }
      }
    }
    // .--bg-2 {
    //   height: 130vw;
    //   margin-top: -30vw;

    //   .hero-container {
    //     width: 120vw;
    //     position: absolute;
    //     z-index: -5;
    //     height: 100%;
    //     top: -3vw;
    //     left: -9vw;

    //     .featured-bg {
    //       width: 120vw;
    //       position: relative;
    //       z-index: -5;
    //       height: 100vh;
    //       top: -9vw;
    //       left: -9vw;
    //     }
    //   }
    // }

    //   &::after {
    //     height: 100vh !important;
    //   }
    //   .--container-2 {
    //     padding-top: 25vw;
    //     padding-bottom: 100px;
    //     .--title {
    //       font-size: 1.2em;
    //       line-height: 1.6;
    //       margin: 7vw 0 10vw 0;
    //     }
    //   }
    // }
    .--container-3 {
      text-align: center;
      position: relative;
      &::before {
        background: url("../images/mobile/home/Page3_BG.png");

        content: "";
        position: absolute;
        top: -24vw;
        left: -57vw;
        bottom: 0;
        right: 0;
        background-size: auto 200vw;
        background-repeat: no-repeat;
        background-position: -7vw 5vw;
        background-attachment: scroll;
        // padding: 100px 0;
        z-index: -1;
      }
      &.--max {
        max-width: 100% !important;
        overflow: hidden;
      }
      .--title {
        margin-bottom: 8vw;
        margin-top: 2vw;
      }
      img {
        width: 28vw;
        height: 28vw;
        margin-bottom: 3vw !important;
        transform: inherit !important;
      }
      h5 {
        &.--project-title {
          font-size: 3vw;
          clip-path: none;
        }
      }
      p {
        &.--desc {
          font-size: 3vw;
          // margin-bottom: 50px !important;
          clip-path: none;
        }
      }
    }
  }
  @media (max-width: 650px) {
    .--bg-3 {
      /* padding-bottom: 24vh !important;
      margin-top: 10vh !important; */
    }
    .hero-bg {
      width: 550vw !important;
      margin-right: 450vw;
      margin-top: 88vw;
    }
    /* .carousel-indicators {
      width: 100% !important;
      height: 30px;
      bottom: -1vw;
      display: flex;
      text-align: center;
      margin: 0 !important;
      padding-top: 15vh;
      z-index: -1;
      li {
        height: 20px;
        width: 20px;
        border-radius: 100px;
        border: 1px solid #ffffff;
        background-color: transparent;
        transition: background-color 0.5s;
        margin: 4vw 0 0 3vw;
        cursor: pointer;

        &.active {
          transition: background-color 0.5s;
          background-color: #ffffff;
        }
      }
    } */

    .hero h1 {
      font-size: 7vw !important;
      top: 43% !important;
    }
    .--header {
      min-height: 67vh !important;
    }
    .overlay-blue {
      top: -39vw;
      left: 10vw;
      transition: top 0.5s;
    }
    .--bg-2 {
      background-color: #283a97;
      padding-bottom: 20vh;
      margin-top: 75vw;
    }
    .overlay-blue::before {
      height: 20vw;
      width: 76vw;
      left: -17vw;
    }
    .featured-bg {
      top: -14vw !important;
    }
    .overlay-white {
      height: 24vw;
      top: -19.5vw;
      left: 32vw;
      &::before {
        left: 20vw;
        height: 11.8vw;
      }
    }
    .overlay-white2 {
      height: 24vw;
      top: 96%;
      left: -30vw;

      &::before {
        left: 20vw;
        height: 11.8vw;
      }
    }
    .--carousel-container {
      text-align: -webkit-center !important;
    }
    .--bg-2 {
      // top: 35vh !important;
      .hero-container {
        top: -28vw;
        transition: top 0.5s;
      }
      &::after {
        height: unset !important;
        top: 0;
      }
      .--container-2 {
        padding-top: 5vw !important;
        .--title {
          transform: inherit !important;
        }
      }

      .--carousel-mb {
        .carousel-item {
          height: 200vw;
          opacity: 0;
          transform: 0;
          display: block !important;

          transition: all 0.5s ease-in-out;

          &.true {
            display: block !important;

            opacity: 1;
            transition: all 0.5s ease-in-out 0.2s;

            // height: 100%;
          }
        }
        a.carousel-control-next,
        a.carousel-control-prev {
          display: none;
          span {
            display: none !important;
          }
        }
      }
    }
    .--container-3 {
      .--title {
        transform: inherit !important;
      }
    }
    .--container-4 {
      .--title {
        transform: inherit !important;
        font-family: "gill sans", sans-serif !important;
        letter-spacing: 0.5vw;
        font-weight: 600 !important;
        text-transform: uppercase;
        line-height: 3vw;
        font-size: 2.4vw;
        color: #283a97;
        margin: 1.5vw 0 6.5vw 0;
        text-align: center;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: url("../images/mobile/home/Page4_BG.png") top left;
        background-size: 130% 100%;
        background-repeat: no-repeat;
        background-attachment: scroll;
        padding: 100px 0;
        z-index: -1;
      }
      img {
        clip-path: none !important;
      }
      .--launches-details {
        .--desc-mobile {
          display: block;
          font-size: 3.3vw;
          line-height: 2.5em;
        }
        .--desc {
          display: none;
        }
        width: 100vw;
        grid-template-areas: unset !important;
        grid-template-columns: 26vw 1fr;
        transform: inherit !important;
        display: block;
        text-align: center;
        img {
          &.--img-logo {
            width: 30vw !important;
            margin: 0 auto 5vw;
          }
        }
        p {
          &.--txt {
            font-size: 4.5vw !important;
            letter-spacing: 0.2em;

            @supports (-webkit-touch-callout: none) {
              letter-spacing: 0.1em;
            }
          }
        }
      }
    }
  }
  @media (max-width: 428px) {
    .--header {
      min-height: 67vh !important;

      .hero {
        h1 {
          text-align: center;
        }
      }
    }
    .--bg-2 {
      .--container-2 {
        padding-top: 0vw !important;
        padding: 0 !important;

        .row {
          .col {
            padding: 0 !important;
          }
        }
      }
      .--carousel-mb {
        .carousel-inner {
          img {
            width: 100% !important;
          }
          .carousel-caption {
            margin-top: 8vh;
            p {
              font-size: 3.5vw;
              letter-spacing: 0.3vw;
              line-height: 2.5;
              margin-bottom: 10vh;
            }
          }
        }
      }
      // top: 24vh;
    }
    // .hero-container {
    //     top: 0 !important;
    // }
    // .overlay-blue {
    //     top: 0 !important
    // }
    // .overlay-white {
    //     top: 0 !important;
    // }
    .--home-temokin-img {
      width: 66vw !important;
    }
    .carousel {
      width: 92vw !important;
    }

    .--container-3 {
      // top: 25vh !important;
    }
    button.carousel-control-next {
      display: none !important;
    }

    .--btn-check-our-work-1 {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.3vw;
      font-size: 0.875em;
      color: #283a97;
      // font-weight: 600;
      padding: 3vw 1.5vw;
      border-radius: 0;
      width: 75% !important;
      margin: 0 auto;
      text-align: center;
      outline: 0 !important;
      display: block;
      box-shadow: none !important;
      background-color: #ffffff !important;
      text-decoration: none !important;
    }

    .--btn-check-our-work-2 {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.3vw;
      font-size: 0.875em;
      color: #283a97;
      // font-weight: 600;
      padding: 3vw 1.5vw;
      border-radius: 0;
      margin: 0 auto;
      text-align: center;
      width: 75% !important;
      outline: 0 !important;
      display: block;
      box-shadow: none !important;
      background-color: #ffffff !important;
      text-decoration: none !important;
    }

    .--btn-check-our-work-1:hover {
      background-color: #283a97 !important;
      border: 1px solid white;
      color: white;
    }

    .--btn-check-our-work-2:hover {
      background-color: #283a97 !important;
      border: 1px solid white;
      color: white;
    }
  }
}
