/*
*
|  Page Name       : licensesandregistrations.scss
|  Description     : scss for licenses and registrations page use
|  Created by      : May Altamerano
|  Date Created    : July 2 2021
|  Last Update by  : May Altamerano
|  Last update     : July 2 2021
*
*/
.--licenses-and-registrations {
  overflow-x: hidden;

  .svg {
    position: absolute;

    &--1 {
      top: 85vw;
      right: 0;
      z-index: 9;

      mix-blend-mode: multiply;
    }

    &--2 {
      top: 30vw;
      left: 0;
      transform: translateX(-60%);
    }

    &--3 {
      bottom: -50vw;
      left: -55vw;
      z-index: 9;
      width: 80vw;
    }

    &--4 {
      bottom: -35vw;
      right: -50vw;
      z-index: 9;
      width: 80vw;
    }
  }

  // width: 100vw;
  // overflow-x: hidden;

  .--bg-1 {
    overflow-x: hidden;
    padding: 15vw 0;
    // min-height: 50vw;
    // width: 100%;
    .--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.2em;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 2em;
      font-size: 4.5vw;
      color: #283a97;
      margin: 10.3vw 0 6vw 0;
      text-align: center;
    }
    .--desc {
      font-family: "gill sans medium", sans-serif;
      font-weight: 500 !important;
      margin-bottom: 9.4vw;
      font-size: 2.8vw;
      color: #676ab0;
      line-height: 2em;
      letter-spacing: 0.05em;
      text-align: center;
      text-transform: uppercase;
    }
  }
  .--bg-2 {
    background-color: #676ab0;
    width: 100%;
    position: relative;
    z-index: 3;

    margin-top: 15vw;

    &::before,
    &::after {
      z-index: -1;
    }

    &::before {
      content: "";
      background: url("../images/licensesandregistrations/licenses-bg-before.png")
        bottom center / 100vw no-repeat;

      position: absolute;
      top: 1px;
      left: 0;
      width: 100%;
      height: 40vw;
      transform: translateY(-100%);

      pointer-events: none;
    }

    &::after {
      content: "";
      background: url("../images/licensesandregistrations/licenses-bg-after.png")
        top center / 100vw no-repeat;
      
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40vw;
      transform: translateY(100%);
      margin-bottom: 1px;
    }

    .carousel {
      margin-top: 10vw;
      img {
        height: 100%;
        object-fit: contain;
        width: 35vw;
        object-position: 0 0;
        margin: 0 auto;
        display: block;
      }
      .carousel-caption {
        position: relative;
        right: unset;
        left: unset;
        bottom: 0;
        padding-bottom: 0;
        padding-top: 0;
      }
      .--caption {
        font-family: "gill sans", sans-serif;
        font-weight: 500 !important;
        margin-top: 2vw;
        font-size: 1vw;
        color: #ffffff;
        line-height: 1.9vw;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.2vw;
      }
      .carousel-inner {
        height: 55vw;
      }
      .carousel-control-next,
      .carousel-control-prev {
        height: 50vw;
        span {
          display: none;
        }
      }
      .carousel-control-next {
        background: url("../images/licensesandregistrations/Licenses_Button-02.png")
          center;
        background-size: contain;
        background-repeat: no-repeat;
        background: background 0.3s;
        width: 5vw;
        image-rendering: -webkit-optimize-contrast;
        right: 5vw;
      }
      .carousel-control-prev {
        background: url("../images/licensesandregistrations/Licenses_Button-01.png")
          center;
        background-size: contain;
        background-repeat: no-repeat;
        background: background 0.3s;
        width: 5vw;
        image-rendering: -webkit-optimize-contrast;
        left: 5vw;
      }
    }
  }
  .--bg-3 {
    margin-top: 25vw;

    padding: 20vw 0;
    width: 100%;
    background: url("../images/licensesandregistrations/Licenses_Shapes-03.png")
      bottom 0 right -25vw;
    background-size: 75vw 20vw;
    background-repeat: no-repeat;
    background: background 0.3s;
    background-attachment: scroll;
    position: relative;
    .carousel {
      margin-top: 0;
    }
    .--max {
      max-width: 85%;
    }
    .--header {
      margin-top: 3vw;
    }
    .--title {
      font-family: "gill sans", sans-serif !important;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 1.5em;
      font-size: 4.5vw;
      letter-spacing: 0.2em;
      color: #283a97;
      margin: 0vw 0 2vw 0;
      text-align: center;
      white-space: pre-line;
    }
    .--desc {
      font-family: "gill sans medium", sans-serif !important;

      font-size: 2.5vw;
      text-align: center;
      line-height: 2em;
      letter-spacing: 0.15em;
      line-height: 2.5em;
      margin-top: 5vw;

      color: #676ab1;
    }
    .--text-container {
      display: flex;
      justify-content: flex-start; 
      align-items: center;
      flex-direction: column;
      height: 100%;
      position: relative;
      right: unset;
      left: unset;
      bottom: 0;
      padding-top: 10vw;
      hr {
        background-color: #283997;
        height: 2px;
        width: 10vw;
        opacity: 1;
      }
      .--desc {
        font-family: "gill sans", sans-serif;
        font-weight: 500 !important;
        margin-bottom: 0;
        margin-top: 1vw;
        letter-spacing: 0.2vw;
        font-size: 1.1vw; 
        color: #283997;
        line-height: 2.5vw;
        text-align: center;
        text-transform: uppercase;
      }
    }
    .carousel {
      img {
        height: auto;
        object-fit: contain;
        width: 34vw;
        object-position: 0 0;
        margin: 0 auto;
        display: block;
      }
      .carousel-item {
        height: 54vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .carousel-caption {
        position: relative;
        right: unset;
        left: unset;
        bottom: 0;
        padding-bottom: 0;
        padding-top: 0;
      }
      .--caption {
        font-family: "gill sans", sans-serif;
        font-weight: 500 !important;
        margin-top: 2vw;
        font-size: 1vw;
        color: #676ab0;
        line-height: 1.9vw;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.2vw;
      }
      .carousel-inner {
        height: 55vw;
      }
      .carousel-control-next,
      .carousel-control-prev {
        height: 50vw;
        span {
          display: none;
        }
      }
      .carousel-control-next {
        background: url("../images/licensesandregistrations/Licenses_Button-04.png")
          center;
        background-size: contain;
        background-repeat: no-repeat;
        background: background 0.3s;
        width: 4vw;
        image-rendering: -webkit-optimize-contrast;
        right: 0;
      }
      .carousel-control-prev {
        background: url("../images/licensesandregistrations/Licenses_Button-03.png")
          center;
        background-size: contain;
        background-repeat: no-repeat;
        background: background 0.3s;
        width: 4vw;
        image-rendering: -webkit-optimize-contrast;
        left: 0;
      }
    }

    .qas {
      .qa__descriptions {
        white-space: pre-line;
      }
    }
  }

  .header-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 125vw;

    img {
      width: 150vw;
      height: 120vw;
      // object-fit: cover;
      object-position: top center;
      transform: translateY(0%);
      margin-left: -30vw;
    }
    /* background: url("../images/licensesandregistrations/Licenses_BG-01.png") top -40.2vw right -27.5vw;
        background-repeat: no-repeat;
        background-size:  140vw auto !important;
        transition: background 0.3s;
        width: 100%;
        position: absolute;
        z-index: -5;
        height: 80vw;
        top: 0;
        &::after {
            content: "";
            background: url("../images/licensesandregistrations/Licenses_Shapes-01.png")  top -12.7vw right -1.8vw;
            background-repeat: no-repeat;
            background-size: 106vw 80vw !important;
            transition: background 0.3s;
            width: 100%;
            position: absolute;
            z-index: 5;
            height: 80vw;
            top: 0;
        } */
  }
  .license-shape {
    background: url("../images/licensesandregistrations/Licenses_Shapes-03.png")
      bottom left 12vw;
    background-size: 70vw 20vw;
    background-repeat: no-repeat;
    background: background 0.3s;
    background-attachment: scroll;
    height: 21vw;
    width: 59.5vw;
    transform: scaleX(-1) scaleY(-1);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .qualityassurance-bg {
    background: url("../images/licensesandregistrations/Licenses_BG-03.png")
      bottom center;
    background-repeat: no-repeat;
    background-size: 100vw auto !important;
    transition: background 0.3s;
    width: 100%;
    position: absolute;
    z-index: -5;
    height: 105vw;
    top: -31vw;
  }
  .qualityassurance-shape {
    background: url("../images/licensesandregistrations/Licenses_Shapes-02.png")
      top right -45vw;
    background-repeat: no-repeat;
    background-size: 155vw auto !important;
    transition: background 0.3s;
    width: 100%;
    position: absolute;
    height: 19vw;
    top: -2vw;
    z-index: -2;
  }

  .licenses {
    $spacing: 6vw;
    gap: $spacing;

    & > *:not(:nth-of-type(1))::before {
      //   background: red;
      position: absolute;
      content: "";
      top: -$spacing / 2;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      height: 1px;
      opacity: 0.5;
      background: white;
    }
  }
  .license {
    font-family: "gill sans", sans-serif !important;
    color: white;
    font-size: 1vw;
    margin: 0 auto;
    text-align: center;
    padding: 13vw 0;

    position: relative;

    &:nth-child(3) {
      .license__image {
        width: 40vw;
      }
    }

    h4,
    h5 {
      margin: 0;
    }

    &__content {
      white-space: pre-line;

      /* & > *:last-child:not(:only-child) {
        line-height: 2em;
      } */

      &--name {
        font-size: 3vw;
        letter-spacing: 0.2em;
        line-height: 1.8em;

        &:first-of-type {
          margin-top: 1em !important;
        }

        &:last-of-type:not(:first-of-type) {
          margin-top: 2em !important;
        }
      }
      &--alt {
        font-size: 2.8vw;
        letter-spacing: 0.1em;
        line-height: 1.8em;
        font-family: "gill sans medium", sans-serif !important;
        margin-top: 1em !important;
      }
    }

    &__image {
      width: 30vw;
      margin: 0 auto;
    }
  }
  .licenses-vector__bottom {
    position: absolute;
    bottom: -7%;
    width: 100%;
  }

  .qas {
    $spacing: 6vw;

    margin-top: 12vw;
    gap: $spacing;
    /* margin-left: 0;
    margin-right: 0; */
    flex-direction: column;
    align-items: center;

    & > * {
      position: relative;

      &:not(:first-child)::before {
        content: "";
        position: absolute;
        top: -$spacing / 2;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 1px;
        background: #283a97;
        opacity: 0.4;
      }
    }
  }
  .qa {
    font-family: "gill sans", sans-serif !important;
    color: #283a97;
    text-align: center;
    padding: 20vw 0;

    font-size: 1vw;

    &:nth-child(4) {
      .qa__image {
        width: 60vw;
        height: 18vw;
      }
    }

    h4,
    h5 {
      margin: 0;
    }

    &__image-container {
      height: 12vw;
      position: relative;
      margin: 0 auto;

      &--iso {
        height: 30vw;
      }
    }

    &__image {
      height: 100%;

      &--iso {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
      }

      &--cidb {
        // height: 2vw;
        height: 100%;
      }

      &--jccd {
        // height: 3vw;
        height: 100%;
      }
    }

    &__descriptions {
      margin-top: 3vw;
    }

    &__description {
      margin-top: 5vw;

      /* &:not(:first-child) {
        margin-top: 5vw;
      } */
    }

    &__sub {
      font-family: "gill sans medium", sans-serif !important;
      font-size: 2.8vw;
      letter-spacing: 0.2em;
      line-height: 1.5em;
      color: #676ab1;
    }

    &__name {
      font-size: 3vw;
      letter-spacing: 0.2em;
      line-height: 1.5em;
      color: #676ab1;
      margin-top: 2vw !important;
    }

    // margin-top: 5vw; 
  }

  @media (max-width: 650px) {
    .--licenses-and-registrations {
      .--bg-1 {
        .--title {
          font-size: 2.5vw !important;
        }
      }
    }

    .svg {
      position: absolute;

      &--2 {
        top: 30vw;
        left: 0;
        transform: translateX(-60%);
        width: 50vw;
      }
    }
  }

  @media (max-width: 1024px) {
    .svg {
      position: absolute;

      &--1 {
        top: 95vw;
        right: 0;
        z-index: 9;
        width: 30vw;

        mix-blend-mode: multiply;
      }

      &--2 {
        top: 30vw;
        left: 0;
        transform: translateX(-60%);
        width: 50vw;
      }
    }
  }
}
