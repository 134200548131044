/*
*
|  Page Name       : propertydevelopment.scss
|  Description     : scss for property development page use
|  Created by      : May Altamerano
|  Date Created    : July 2 2021
|  Last Update by  : May Altamerano
|  Last update     : July 2 2021
*
*/
.carousel.overlay-carousel {
  // min-height: 70vh;

  &--building {
    // min-height: unset;
    .carousel-caption {
      margin-top: -15%;

      .--bottom-detail {
        position: relative;
      }
      .--desc {
        &::after {
          display: none;
        }
      }
    }

    .carousel-indicators {
      top: 5vw !important;
    }

    .carousel-item {
      min-height: 130vw;
      &--0 {
        img {
          object-position: -26vw 5vw !important;
          height: 110% !important;
        }
      }

      &--1 {
        img {
          object-position: center 5vw !important;
          height: 110% !important;
          width: 115vw !important;
        }
      }

      &--2 {
        img {
          object-position: right bottom !important;
        }
      }
    }
  }

  &--property-development {
    .carousel-item {
      min-height: 200vw;
    }
    .carousel-caption {
      .--desc {
        margin-top: 5vw;
      }
    }
  }

  .carousel-item {
    position: relative;
    display: flex;

    justify-content: end;
    align-items: center;
    opacity: 0;

    transition: opacity 0.2s ease-in-out;

    &.true {
      opacity: 1;
      // height: 100%;
    }
  }
  .carousel-inner {
    height: 100%;

    img {
      position: absolute;
      height: 65vw;

      height: 100%;
      width: 100%;

      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      opacity: 0.4;
    }
  }
  .carousel-control-prev,
  .carousel-control-next {
    display: none !important;
  }
  .carousel-indicators {
    bottom: 22vw;
    left: unset;
    // width: 45vw;
    margin-left: unset;
    margin-right: unset;
    justify-content: flex-start;
    padding-left: 5.7vw;

    position: relative;
    top: unset;
    left: unset;
    bottom: unset;
    right: unset;
    padding-left: 0;
    display: none;

    &.custom {
      display: flex;
      gap: 5vw;
    }
    li {
      height: 3vw;
      width: 3vw;
      border-radius: 100px;
      border: 1px solid #283a97;
      background-color: transparent;
      transition: background-color 0.5s;

      :hover {
        background-color: #283a97;
        transition: background-color 0.5s;
      }
      &.active {
        background-color: #283a97;
        transition: background-color 0.5s;
      }
    }
  }
  .carousel-caption {
    position: relative;
    right: 0;
    bottom: 0;
    left: unset;
    top: 0;

    padding: 0 10vw;

    color: #fff;
    text-align: left;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: 100%;
    .--title {
      white-space: pre-line;
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.2em;
      // font-weight: 700 !important;
      text-transform: uppercase;
      line-height: 1.1;
      font-size: 3.5vw;
      color: #283a97;
      margin: 0 0 0.8vw 0;
      text-align: center;
      //   padding-left: 5.7vw;
    }
    .--desc {
      white-space: pre-line;
      font-family: "gill sans medium", sans-serif;
      font-weight: 500 !important;
      margin-bottom: 2.4vw;
      // margin-top: 5vw;
      color: #283a97;
      line-height: 1.5em;
      text-align: center;
      position: relative;

      font-size: 2.8vw;
      letter-spacing: 0.2em;
    }
    .--hr {
      height: 1px;
      width: 27vw;
      // background-color: #283a97;
      opacity: 1 !important;
      margin-bottom: 2vw;
    }
    .--bottom-detail {
      letter-spacing: 0.2vw;
      color: #283a97;
      text-align: center;
      margin-bottom: 10vw;
      position: relative;

      &:not(:last-child):after {
        width: 58vw;

        content: "";
        position: absolute;
        bottom: -17.5px;
        left: 50%;
        transform: translateX(-50%);
        height: 0.5px;
        background-color: #676ab1;
        opacity: 0.9;
      }

      &:last-child {
        margin-bottom: 5vw;
      }

      span.--title {
        font-family: "gill sans", sans-serif !important;
        // font-weight: 700 !important;
        line-height: normal;
        font-size: 3vw;
        text-transform: capitalize;
        text-align: center;
        // padding-left: 5.7vw;
        margin-bottom: 0;
      }
      span {
        display: block;
        font-family: "gill sans medium", sans-serif !important;
        // font-weight: 300 !important;
        line-height: normal;
        font-size: 3vw;
        letter-spacing: 0.2em;
      }
    }
    .--buttons {
      margin-top: 7vw;
      display: flex;
      flex-direction: column;
    }
    .--btn-view-project {
      background-color: #283997 !important;
      border: 0 !important;
      border-radius: 0 !important;
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.2em;
      font-size: 0.8rem;
      color: white;
      text-align: center;
      text-transform: uppercase;
      padding: 1em 4em;
      //   margin-left: 5.7vw;
      //   margin-top: 5vw;
      text-decoration: none !important;
      box-shadow: none !important;
      outline: 0 !important;
      z-index: 10;
      position: relative;

      border: 0.2px solid #283a97;

      &:hover {
        color: white;
        background-color: #283997 !important;
      }

      &:last-child:not(:only-of-type) {
        margin-top: 2vw;
      }
    }
    .awards {
      // display: g;
    }
    .award {
      display: flex;
      justify-content: center;
      //   justify-content: space-between;
      gap: 5%;
      margin-top: 6vw;

      &__name {
        // flex: 6;
        width: 45%;
        text-align: left;
        white-space: pre-line;
      }

      &__image {
        // flex: 1;
        width: 25%;
        position: relative;
        opacity: 1;
      }

      /* &:last-child:not(:only-of-type) {
        margin-top: 2vw;
      } */
    }
  }

  /*  @media (max-width: 992px) {
    .carousel {
      height: 100vw;

      .carousel-inner {
        height: 100vw;
        img {
          height: 100vw;
        }
      }
      .carousel-caption {
        width: 90%;
        left: 50px;
        .--title {
          font-size: 1.125em;
          line-height: 1.6;
        }
        .--desc {
          font-size: 0.875em;
          line-height: 1.6;
          br {
            display: none;
          }
        }
        .--hr {
          margin-left: 5.7vw;
          width: 80vw;
        }
        span.--title,
        span {
          font-size: 0.875em !important;
        }
        .--btn-view-project {
          font-size: 0.875em !important;
        }
        .--bottom-detail {
          margin-bottom: 30px;
        }
      }
      .carousel-indicators {
        width: 92vw;
        bottom: 25vw;
        li {
          height: 21px;
          width: 21px;
        }
      }
    }
    .--carousel-container {
      position: relative;
      .--btn-carousel-container {
        position: absolute;
        width: 100%;
        top: 0;
        z-index: 10;
        height: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }
      .carousel-indicators {
        display: none;
      }
      button {
        &.carousel-control-prev {
          background: url("../images/licensesandregistrations/Licenses_Button-03.png")
            center;
          transition: background 0.3s;
          background-attachment: local;
          background-repeat: no-repeat;
          background-size: contain;
          width: 9vw;
          display: block;
          padding: 0;
          cursor: pointer;
          height: 9vw;
          opacity: 1;
          outline: 0 !important;
          box-shadow: none !important;
          border: 0 !important;
          margin-left: 3vw;
          margin-top: auto;
          span {
            display: none !important;
          }
        }
        &.carousel-control-next {
          background: url("../images/licensesandregistrations/Licenses_Button-04.png")
            center;
          transition: background 0.3s;
          background-attachment: local;
          background-repeat: no-repeat;
          background-size: contain;
          width: 9vw;
          display: block;
          padding: 0;
          cursor: pointer;
          height: 9vw;
          opacity: 1;
          outline: 0 !important;
          box-shadow: none !important;
          border: 0 !important;
          margin-right: 3vw;
          margin-top: auto;
          span {
            display: none !important;
          }
        }
      }
      .--carousel-mb {
        .carousel-inner {
          img {
            width: 100% !important;
            margin: 0 auto;
          }
          .carousel-caption {
            position: absolute;
            right: 13vw;
            left: 13vw;
            margin-top: 8vw;
            width: auto !important;
            h3 {
              font-size: 1.125em;
              margin-bottom: 1.5vw;
              letter-spacing: 0.4vw;
            }
            p {
              font-size: 0.875em;
              letter-spacing: 0.2vw;
              br {
                display: none;
              }
            }
            .--hr {
              width: 58vw;
            }
          }
        }
        a.carousel-control-next,
        a.carousel-control-prev {
          display: none;
          span {
            display: none !important;
          }
        }
      }
    }
  }
  @media (max-width: 700px) {
    .carousel {
      height: 115vw;
      .carousel-inner {
        height: 115vw;
        img {
          height: 115vw;
        }
      }
      .carousel-caption {
        left: 25px;
      }
    }
  }
  @media (max-width: 570px) {
    .carousel {
      height: 140vw;
      .carousel-inner {
        height: 140vw;
        img {
          height: 140vw;
        }
      }
      .carousel-caption {
        left: 25px;
      }
      .carousel-indicators {
        bottom: 28vw;
      }
    }
  }
  @media (max-width: 500px) {
    .carousel {
      height: 200vw;
      .carousel-inner {
        height: 200vw;
        img {
          height: 200vw;
        }
      }
      .carousel-caption {
        left: 25px;
        padding-bottom: 35.3vw;
      }
      .carousel-indicators {
        bottom: 50vw;
      }
    }
  } */
}
