.ventures {
  overflow-x: hidden;

  .svg {
    position: absolute;

    &--1 {
      left: 0;
      top: 33%;
      width: 30vw;
    }

    &--2 {
      top: 50%;
      right: 0;
      width: 40vw;
      // transform: translateX(-100%);
    }
  }

  .hero {
    height: 100vh;
    background: url("../images/otherbusinessventures/hero-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: -140vw center;

    display: grid;
    place-content: center;

    overflow: hidden;

    &__content {
      font-family: "gill sans", sans-serif !important;

      width: 40vw;
      margin-top: -3vw;

      p,
      h1 {
        margin: 0;
      }
    }

    &__title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.5vw;
      text-transform: uppercase;
      line-height: 3.1vw;
      font-size: 2.3vw;
      color: #283a97;
      // margin: 10.3vw 0 3.4vw 0;
      text-align: center;
    }

    &__desc {
      padding-top: 4vw;
      font-family: "gill sans medium", sans-serif;
      font-weight: 500 !important;
      margin-bottom: 9.4vw;
      font-size: 1vw;
      color: #676ab0;
      line-height: 1.9vw;
      text-align: center;
      text-transform: uppercase;
    }
  }

  @media (max-width: 1024px) {
    .hero {

      &__content {
        font-family: "gill sans", sans-serif !important;

        width: 100vw;
        margin-top: -35vw;

        p,
        h1 {
          margin: 0;
        }
      }

      &__title {
        font-size: 3.5vw;
        line-height: 200%;
        letter-spacing: 5px;
      }

      &__desc {
        padding-top: 10vw;

        font-size: 2.5vw;
      }

    }
  }

  @media (max-width: 769px) {
    .hero {
      background: url("../images/otherbusinessventures/hero-bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      background-position: -49vw center;
      height: 75vh;
      place-content: center;

      &__content {
        font-family: "gill sans", sans-serif !important;

        width: 100vw;
        margin-top: -30vw;

        p,
        h1 {
          margin: 0;
        }
      }

      &__title {
        font-size: 3.5vw;
        line-height: 200%;
        letter-spacing: 5px;
      }

      &__desc {
        padding-top: 10vw;

        font-size: 2.5vw;
      }

      .--image {
        width: 200vw;
        height: 70vw;
        position: absolute;
        // margin-top: 80vh;
        // margin-left: -20%;
        bottom: -43vw;
        left: -25vw;
        mix-blend-mode: multiply;
      }
    }
  }

  @media (max-width: 414px) {
    .hero {
      background: url("../images/otherbusinessventures/hero-bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      background-position: -135vw center;
    }
  }

  @media (max-width: 414px) {
    .hero {
      background: url("../images/otherbusinessventures/hero-bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      background-position: -140vw center;
    }
  }

  @media (max-width: 412px) {
    .hero {
      background: url("../images/otherbusinessventures/hero-bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      background-position: -146vw center;
    }
  }

  @media (max-width: 393px) {
    .hero {
      background: url("../images/otherbusinessventures/hero-bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      background-position: -140vw center;
    }
  }

  @media (max-width: 390px) {
    .hero {
      background: url("../images/otherbusinessventures/hero-bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      background-position: -140vw center;
    }
  }

  @media (max-width: 375px) {
    .hero {
      background: url("../images/otherbusinessventures/hero-bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      background-position: -97vw center;
    }
  }

  @media (max-width: 360px) {
    .hero {
      background: url("../images/otherbusinessventures/hero-bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      background-position: -128vw center;
    }
  }
}
