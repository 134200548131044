/*
*
|  Page Name       : projects.scss
|  Description     : scss for projects page use
|  Created by      : May Altamerano
|  Date Created    : July 6 2021
|  Last Update by  : May Altamerano
|  Last update     : July 6 2021
*
*/
.--projects {
  min-height: 100vh;
  // margin-top: -9.1vw;
  width: 100%;
  // background: url("../images/projects/CivilEng_BG-01.png") top center;
  transition: background 0.3s;
  // background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100vw auto;
  position: relative;
  overflow: hidden;
  .--bg-1 {
    min-height: 50vw;
    padding: 15vw 0 0;
    .--max {
      max-width: 80%;
    }
    .--project-details {
      width: 100%;
      position: relative;
      img {
        width: 58vw !important;
        margin: 0 auto;

        width: 100% !important;
        margin: 0;
      }
    }
    .--about-content {
      max-width: 63vw;
      margin: 3vw auto 5vw auto;
      display: block;

      h3 {
        white-space: pre-line;
      }
    }
  }
  @media (max-width: 992px) {
    background-size: cover !important;
    .--bg-1 {
      padding: 25vw 0 0 0 !important;
      .--max {
        max-width: 90% !important;
        .col-md-7,
        .col-md-5 {
          width: 100%;
          max-width: 100%;
        }
      }
      .--project-details {
        img {
          width: 100% !important;
          margin: 0 auto;
          object-fit: contain;
        }
      }
      .--about-content {
        max-width: 100%;
        margin: 30px auto !important;
        h3 {
          // font-size: 1.125em !important;
          text-align: center !important;
          br {
            display: none;
          }
        }
        hr {
          width: 50%;
          margin-left: 25%;
          height: 1px !important;
          opacity: 0.7;
        }
        p {
          // font-size: 0.875em !important;
          text-align: center !important;
          br {
            display: none;
          }
        }
        .col-md-7 {
          div {
            margin-left: 0 !important;
            margin-top: 50px;
            h3 {
              text-align: left !important;
              // font-size: 0.875em !important;
            }
            p {
              text-align: left !important;
              // font-size: 0.875em !important;
              br {
                display: none;
              }
            }
          }
        }
        .col-md-5 {
          h3 {
            text-align: left !important;
          }
          p {
            text-align: left !important;
          }
        }
      }
    }
  }
}
