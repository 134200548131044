/*
*
|  Page Name       : termsofservice.scss
|  Description     : scss for terms of service page use
|  Created by      : Michael Dela Rosa
|  Date Created    : Oct 25 2022
|  Last Update by  : Michael Dela Rosa
|  Last update     : Oct 25 2022
*
*/

.--terms-of-service {
  position: relative;
  overflow-x: hidden;

  .svg {
    position: absolute;
    z-index: 3;

    &--1 {
      top: 6%;
      right: -32%;

      width: 80vw;
      height: 150px;
    }

    &--2 {
      top: 0;
      right: 0;

      width: 20vw;
    }

    &--3 {
      top: 35%;
      right: 0;

      width: 10vw;
    }

    &--4 {
      top: 65%;
      left: 0;

      width: 10vw;
    }
  }

  .--subtitle {
    width: 100%;
    text-align: center !important;
    font-family: "gill sans light", sans-serif !important;
    color: #283a97;
    letter-spacing: 0.15vw;
  }
  .tac {
    margin-bottom: 15px !important;
  }
  .--subContent {
    width: 100%;
    text-align: center;
    // margin-top: 8%;
    font-size: 0.8vw;
    text-transform: uppercase;
  }

  .textBody {
    width: 100%;
    color: white;
    letter-spacing: 0.2em;
    padding-bottom: 30vw;
    padding-top: 20vw;
    position: relative;
    font-size: 1.1vw;

    &-bg {
      position: absolute;
      width: 100vw;
      height: 90%;
      bottom: 0;
      left: 0;
      z-index: -1;

      background-color: #283a97;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 99.3%;
        width: 100vw;
        height: 40vw;

        background: url("../images/termsofservice/svg-06.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left bottom;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: -0%;
      width: 100vw;
      height: 50vw;
      

      background: url("../images/termsofservice/628.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left bottom;
    }
  }

  .textBodyTitle {
    text-align: left;
    // font-size: 12pt !important;
    margin-bottom: 10vh;
    font-family: "gill sans medium", sans-serif !important;
  }

  .bodyContent {
    line-height: 3;
    font-family: "gill sans medium", sans-serif !important;
  }

  .separator {
    margin-top: 7vh;
    margin-bottom: 7vh;
  }

  .--subContent {
    width: 100%;
    text-align: center;
    margin-top: 5vh;
    line-height: 2;
    letter-spacing: 0.2vw;
    color: #676ab1;
    font-family: "gill sans medium", sans-serif !important;
  }

  .modal-dialog {
    max-width: 50vw;
    margin: 5vw auto 2vw auto;
  }

  .--bg-1 {
    padding: 10vw 0 0 0;
    min-height: 50vw;
    width: 100%;

    .--max {
      max-width: 86%;

      img {
        margin: 0 auto 3vw auto;
        display: block;
        height: 8vw;
        width: 8vw;
        object-fit: contain;
        image-rendering: pixelated;
      }
    }

    h1.--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.4vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 1;
      font-size: 2vw;
      color: #283a97;
      margin: 4vw 0 10.5vw 0;
      text-align: center;
    }

    h3.--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.2vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 1.1;
      font-size: 1.19vw;
      color: #283a97;
      margin: 0 0 0.5vw 0;
      text-align: center;
    }

    p.--desc {
      font-family: "gill sans medium", sans-serif;
      font-weight: 500 !important;
      margin-bottom: 2.4vw;
      font-size: 1.2vw;
      color: #283a97;
      line-height: 1.4vw;
      text-align: center;
      letter-spacing: 0.17vw;
    }
  }

  .--bg-2 {
    padding: 27.5vw 0 0 0;
    min-height: 80vw;
    width: 100%;
    position: relative;
    margin-top: -10%;

    .--max {
      max-width: 100%;
      min-height: 42vw;
      z-index: 2;
      position: relative;
    }

    h3.--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.2vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 1.1;
      font-size: 1.05vw;
      color: #ffffff;
      margin: 0 0 3.5vw 0;
      text-align: center;
    }

    form {
      margin-left: 5vw;

      .form-group {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
      }

      label {
        font-family: "gill sans", sans-serif !important;
        letter-spacing: 0.1vw;
        font-weight: 600 !important;
        text-transform: uppercase;
        line-height: 1.1;
        font-size: 0.8vw;
        color: #ffffff;
        margin: 0 0.5vw 0.5vw 0;
        text-align: right;
      }

      input {
        color: #ffffff;
        border: 1px solid #ffffff !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        outline: 0 !important;
        height: 4vw;
        padding: 1vw;
        background-color: transparent !important;
        background: transparent !important;
        font-family: "gill sans medium", sans-serif !important;
        letter-spacing: 0.1vw;
      }

      textarea {
        color: #ffffff;
        border: 1px solid #ffffff !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        outline: 0 !important;
        height: 4vw;
        padding: 1vw;
        background-color: transparent !important;
        background: transparent !important;
        resize: none;
        font-family: "gill sans medium", sans-serif !important;
        letter-spacing: 0.1vw;
      }

      .--btn-send {
        background: url("../images/contactus/Contact_Button-01.png") top center;
        background-repeat: no-repeat;
        transition: background 0.3s;
        image-rendering: -webkit-optimize-contrast;
        width: 15vw;
        height: 60px;
        background-position: center;
        background-size: contain;
        border: 0 !important;
        border-radius: 0 !important;
        outline: 0 !important;
        display: block;
        margin: 5vw auto 0 11.7vw;
        outline: 0 !important;
        box-shadow: none !important;

        &:focus {
          outline: 0 !important;
        }
      }
    }

    .--map-container {
      width: 35vw;
      height: 30vw;
      padding-top: 2vw;
      position: relative;

      iframe {
        margin-left: -2.5vw;
        width: 37.5vw;
      }
    }

    // .header-shapes {
    //   position: absolute;
    //   height: 100vw;
    //   width: 100%;
    //   top: 3vw;

    //   overflow: hidden;

    //   &::before {
    //     content: "";
    //     height: 10.8vw;
    //     width: 57.5vw;
    //     background-color: transparent;
    //     border: 1px solid #ffffff;
    //     position: absolute;
    //     top: -2vw;
    //     left: -42vw;
    //     transition: height 0.3s;
    //     transition: width 0.3s;
    //     transform: skew(321deg, 16.5deg);
    //   }

    //   &::after {
    //     content: "";
    //     height: 11.8vw;
    //     width: 67.5vw;
    //     background-color: #9c9eca8f;
    //     position: absolute;
    //     top: 12vw;
    //     right: -27vw;
    //     transition: height 0.3s;
    //     transition: width 0.3s;
    //     transform: skew(318deg, 15deg);
    //   }
    // }
  }

  .header-bg {
    background: url("../images/privacypolicy/header-bg.png");
    background-repeat: no-repeat;
    background-size: 240vw 240vw !important;
    background-position: -100vw top;

    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;
    position: absolute;
    height: 240vw;
    width: 100%;
    top: -1.8%;
    z-index: -3;
  }

  .form-bg {
    position: absolute;
    top: -5vw;
    left: 0;
    height: 104%;
    width: 100%;
    overflow: hidden;
    z-index: -1;

    /* &::before {
      content: "";
      border-top: 27vw solid transparent;
      border-left: 100vw solid #283a97;
      height: 100% !important;
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
    } */
  }

  @media (max-width: 1024px) {
    background: url("../images/contactus/Contact_BG-01.png") top -8vw right;
    background-size: 155vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;

    .header-bg {
      display: none;
    }

    .form-bg {
      background-size: 193vw 194vw !important;
      background-repeat: no-repeat;
      background-attachment: scroll;
      transition: background 0.3s;
      image-rendering: -webkit-optimize-contrast;
      position: absolute;
      height: 170vw;
      width: 100%;
      // top: -20vw;
      z-index: 0;
    }

    .--bg-1 {
      padding: 200px 0 0 0;

      .--max {
        max-width: 90%;
      }

      h1.--title {
        font-size: 1.5em;
      }

      img {
        height: 10vw;
        width: 10vw;
      }

      h3 {
        &.--title {
          font-size: 0.875em;
        }
      }

      p {
        &.--desc {
          font-size: 0.875em;
          line-height: 1.4;
        }
      }

      .row {
        &:nth-child(2) {
          .col-md-4 {
            width: 50%;
            max-width: 50%;

            &:last-child {
              width: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }

    .--bg-2 {
      padding: 30.5vw 0 0 0;

      .--max {
        z-index: 2;
        position: relative;
      }

      .col-md-6 {
        width: 100%;
        max-width: 100%;
      }

      h3 {
        &.--title {
          font-size: 1.5em;
          margin: 0 0 6.5vw 0;
        }
      }

      form {
        label {
          font-size: 0.875em;
        }

        input {
          height: 60px;
        }

        textarea {
          height: 60px;
        }

        .--btn-send {
          width: 215px;
          height: 45px;
          margin: 5vw auto 0 23.7vw;
        }
      }

      .--map-container {
        width: 100%;
        height: 50vw;
        padding-top: 2vw;
        position: relative;
        display: block;
        margin: 100px auto;

        iframe {
          margin-left: 0;
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 920px) {
    background: url("../images/contactus/Contact_BG-new.png") top -6.2vw right;
    background-size: 145vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;

    .svg {
      position: absolute;
      z-index: 3;
  
      &--1 {
        top: 11.5%;
        right: -22%;
  
        width: 50vw;
        height: 200px;
      }
    }

    .--bg-1 {
      h1.--title {
        font-size: 22px;
      }
    }

    .--subtitle {
      font-size: 18px;
    }

    .textBodyTitle {
      font-size: 3vw;
      text-align: center;
      margin-top: 10vw;
      margin-bottom: 12vh;
    }

    .bodyContent {
      font-size: 2vw;
      text-align: center;
      line-height: 2;
    }

    .--subContent {
      font-size: 1.8vw;
      line-height: 2.5em;
    }

    .--bg-2 {
      margin-top: -33vw;
    }

    .textBody {
      width: 100%;
      color: white;
      letter-spacing: 0.2em; 
      padding-bottom: 30vw;
      padding-top: 40vw;
      position: relative;
      font-size: 2vw;
  
      &-bg {
        position: absolute;
        width: 100vw;
        height: 90%;
        bottom: 0;
        left: 0;
        z-index: -1;
  
        background-color: #283a97;
  
        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 99.3%;
          width: 100vw;
          height: 45vw;
  
          background: url("../images/termsofservice/svg-06.svg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: left bottom;
        }
      }
  
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: -0%;
        width: 100vw;
        height: 50vw;
        
  
        background: url("../images/termsofservice/628.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left bottom;
      }
    }
  }

  @media (max-width: 740px) {
    background: url("../images/contactus/Contact_BG-new.png") top -8vw right;
    background-size: 180vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;

    .textBody {
      width: 100%;
      color: white;
      letter-spacing: 0.2em;
      padding-bottom: 30vw;
      padding-top: 20vw;
      position: relative;
      font-size: 1.1vw;
  
      &-bg {
        position: absolute;
        width: 100vw;
        height: 90%;
        bottom: 0;
        left: 0;
        z-index: -1;
  
        background-color: #283a97;
  
        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 99.3%;
          width: 100vw;
          height: 40vw;
  
          background: url("../images/termsofservice/svg-06.svg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: left bottom;
        }
      }
  
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: -0%;
        width: 100vw;
        height: 50vw;
        
  
        background: url("../images/termsofservice/628.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left bottom;
      }
    }

    .svg {
      position: absolute;
      z-index: 3;
  
      &--1 {
        top: 6%;
        right: -32%;
  
        width: 80vw;
        height: 150px;
      }
    }

    .form-bg {
      height: 195vw;
    }

    .--subContent {
      font-size: 3vw;
      line-height: 2.5em;
    }

    .--bg-1 {
      h1.--title {
        font-size: 22px;
      }
    }

    .--subtitle {
      font-size: 18px;
    }

    .textBodyTitle {
      font-size: 14px;
      text-align: center;
      margin-top: 30vh;
      margin-bottom: 12vh;
    }

    .bodyContent {
      font-size: 12px;
      text-align: center;
      line-height: 2;
    }
  }

  @media (max-width: 600px) {
    background: url("../images/contactus/Contact_BG-new.png") top -8vw right;
    background-size: 200vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;

    .--bg-1 {
      padding: 80px 0 0 0;

      // .header-shapes {
      //   &::before {
      //     top: 13vw;
      //   }
      // }
    }

    .--bg-1 {
      h1.--title {
        font-size: 22px;
      }
    }

    .--subtitle {
      font-size: 18px;
    }

    .textBodyTitle {
      font-size: 14px;
      text-align: center;
      margin-top: 30vh;
      margin-bottom: 12vh;
    }

    .bodyContent {
      font-size: 12px;
      text-align: center;
      line-height: 2;
    }
  }

  @media (max-width: 566px) {
    background: url("../images/contactus/Contact_BG-new.png") top -8vw right;
    background-size: 220vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;

    .form-bg {
      background-size: 350vw 370vw !important;
      height: 320vw !important;
      z-index: 0;
      bottom: 0;
      // top: -44vw;
    }

    .--bg-2 {
      padding: 0 0 0 0;

      form {
        margin-left: 0;
        width: 90%;
        margin: 0 auto;

        label {
          text-align: left !important;
        }
      }
    }

    .--bg-1 {
      h1.--title {
        font-size: 22px;
      }
    }

    .--subtitle {
      font-size: 18px;
    }

    .textBodyTitle {
      font-size: 14px;
      text-align: center;
      margin-top: 30vh;
      margin-bottom: 12vh;
    }

    .bodyContent {
      font-size: 12px;
      text-align: center;
      line-height: 2;
    }
  }

  // @media (max-width: 500px) {
  //   background: url("../images/contactus/Contact_BG-new.png") top -8vw right;
  //   background-size: 235vw auto;
  //   background-repeat: no-repeat;
  //   background-attachment: scroll;
  //   transition: background 0.3s;
  //   image-rendering: -webkit-optimize-contrast;

  //   .--bg-2 {
  //     padding: 0 0 0 0;

  //     form {
  //       margin-left: 0;
  //       width: 90%;
  //       margin: 0 auto;

  //       label {
  //         text-align: left !important;
  //       }
  //     }
  //   }

  //   .--bg-1 {
  //     h1.--title {
  //       font-size: 22px;
  //     }
  //   }

  //   .--subtitle {
  //     font-size: 18px;
  //   }

  //   .textBodyTitle {
  //     font-size: 14px;
  //     text-align: center;
  //     margin-top: 30vh;
  //     margin-bottom: 12vh;
  //   }

  //   .bodyContent {
  //     font-size: 12px;
  //     text-align: center;
  //     line-height: 2;
  //   }
  // }

  @media (max-width: 414px) {
    background: url("../images/contactus/Contact_BG-new.png") top -12vw right -38vw;
    background-size: 210vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;

    .--bg-2 {
      padding: 0 0 0 0;
      margin-top: -25.5vw;

      form {
        margin-left: 0;
        width: 90%;
        margin: 0 auto;

        label {
          text-align: left !important;
        }

        .--btn-send {
          margin: 5vw auto 0 auto;
        }
      }
    }

    .--bg-1 {
      h1.--title {
        font-size: 22px;
      }
    }

    .--subtitle {
      font-size: 18px;
    }

    .textBodyTitle {
      font-size: 14px;
      text-align: center;
      margin-top: 30vh;
      margin-bottom: 12vh;
    }

    .bodyContent {
      font-size: 12px;
      text-align: center;
      line-height: 2;
    }
  }

  @media (max-width: 412px) {
    background: url("../images/contactus/Contact_BG-new.png") top -12vw right -38vw;
    background-size: 210vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;

    .--bg-2 {
      padding: 0 0 0 0;
      margin-top: -27vw;

      form {
        margin-left: 0;
        width: 90%;
        margin: 0 auto;

        label {
          text-align: left !important;
        }

        .--btn-send {
          margin: 5vw auto 0 auto;
        }
      }
    }

    .--bg-1 {
      h1.--title {
        font-size: 22px;
      }
    }

    .--subtitle {
      font-size: 18px;
    }

    .textBodyTitle {
      font-size: 14px;
      text-align: center;
      margin-top: 30vh;
      margin-bottom: 12vh;
    }

    .bodyContent {
      font-size: 12px;
      text-align: center;
      line-height: 2;
    }
  }

  @media (max-width: 393px) {
    background: url("../images/contactus/Contact_BG-new.png") top -10vw right -38vw;
    background-size: 210vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;

    .--bg-2 {
      padding: 0 0 0 0;
      margin-top: -27vw;

      form {
        margin-left: 0;
        width: 90%;
        margin: 0 auto;

        label {
          text-align: left !important;
        }

        .--btn-send {
          margin: 5vw auto 0 auto;
        }
      }
    }

    .--bg-1 {
      h1.--title {
        font-size: 22px;
      }
    }

    .--subtitle {
      font-size: 18px;
    }

    .textBodyTitle {
      font-size: 14px;
      text-align: center;
      margin-top: 30vh;
      margin-bottom: 12vh;
    }

    .bodyContent {
      font-size: 12px;
      text-align: center;
      line-height: 2;
    }
  }

  @media (max-width: 390px) {
    background: url("../images/contactus/Contact_BG-new.png") top -10vw right -38vw;
    background-size: 210vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;

    .--bg-2 {
      padding: 0 0 0 0;
      margin-top: -27vw;

      form {
        margin-left: 0;
        width: 90%;
        margin: 0 auto;

        label {
          text-align: left !important;
        }

        .--btn-send {
          margin: 5vw auto 0 auto;
        }
      }
    }

    .--bg-1 {
      h1.--title {
        font-size: 22px;
      }
    }

    .--subtitle {
      font-size: 18px;
    }

    .textBodyTitle {
      font-size: 14px;
      text-align: center;
      margin-top: 30vh;
      margin-bottom: 12vh;
    }

    .bodyContent {
      font-size: 12px;
      text-align: center;
      line-height: 2;
    }
  }

  @media (max-width: 375px) {
    background: url("../images/contactus/Contact_BG-new.png") top -7vw right -38vw;
    background-size: 210vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;

    .--bg-2 {
      padding: 0 0 0 0;
      margin-top: -19vw;

      form {
        margin-left: 0;
        width: 90%;
        margin: 0 auto;

        label {
          text-align: left !important;
        }

        .--btn-send {
          margin: 5vw auto 0 auto;
        }
      }
    }

    .--bg-1 {
      h1.--title {
        font-size: 22px;
      }
    }

    .--subtitle {
      font-size: 18px;
    }

    .textBodyTitle {
      font-size: 14px;
      text-align: center;
      margin-top: 30vh;
      margin-bottom: 12vh;
    }

    .bodyContent {
      font-size: 12px;
      text-align: center;
      line-height: 2;
    }
  }

  @media (max-width: 360px) {
    background: url("../images/contactus/Contact_BG-new.png") top -6vw right -37.5vw;
    background-size: 210vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;

    .--bg-2 {
      padding: 0 0 0 0;
      margin-top: -28vw;

      form {
        margin-left: 0;
        width: 90%;
        margin: 0 auto;

        label {
          text-align: left !important;
        }

        .--btn-send {
          margin: 5vw auto 0 auto;
        }
      }
    }

    .--bg-1 {
      h1.--title {
        font-size: 22px;
      }
    }

    .--subtitle {
      font-size: 18px;
    }

    .textBodyTitle {
      font-size: 14px;
      text-align: center;
      margin-top: 30vh;
      margin-bottom: 12vh;
    }

    .bodyContent {
      font-size: 12px;
      text-align: center;
      line-height: 2;
    }
  }
}
