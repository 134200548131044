.preview-card {
  aspect-ratio: 60/45;
  position: relative;
  transition: opacity 0.2s ease-in-out;

  &__inner {
    position: relative;
    height: 100%;
    display: flex;
    align-items: flex-end;
  }

  &__image {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #283a97;
    opacity: 0;

    transition: opacity 0.2s ease-in-out;
  }

  /* &:hover {
    opacity: 0.5;
  } */

  /* &:hover .preview-card__overlay {
    opacity: 0.4;
  }
  &:hover .caption {
    opacity: 1;
  } */

  .caption {
    width: 90%;
    height: 100%;
    padding: 0 0 10% 8%;

    transition: opacity 0.2s ease-in-out;

    opacity: 0;

    //   background-color: #283a97;

    font-family: "gill sans", sans-serif !important;
    text-transform: uppercase;
    position: relative;

    display: flex;
    align-items: flex-end;

    &__name {
      font-size: 0.85vw;
      color: white;
      letter-spacing: 0.15em;
      line-height: 1.5em;

      margin: 0;
      width: 50%;
      position: relative;
      z-index: 1;
    }

    &__background {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;

      .svg {
        fill: #283a97;
        opacity: 0.9;
      }
    }
  }
}
