/*
*
|  Page Name       : propertprojects.scss
|  Description     : scss for property development projects page use
|  Created by      : May Altamerano
|  Date Created    : July 6 2021
|  Last Update by  : May Altamerano
|  Last update     : July 6 2021
*
*/

.--projects {
  min-height: 100vh;
  // margin-top: -9.1vw;
  width: 100%;
  // background: url("../images/propertyprojects/PropertyDev_BG-01.png") top center;
  transition: background 0.3s;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100vw 170vh;
  position: relative;
  overflow: hidden;

  .svg {
    position: absolute;

    &--100 {
      top: 13%;
      left: -50%;
      width: 70%;
      z-index: 1;
    }
    &--1 {
      top: 5%;
      left: -55%;
      width: 75%;
      z-index: 1;
    }
    &--2 {
      top: -5%;
      right: 0;
      width: 21%;
    }
    &--3 {
      top: 9%;
      left: 0;
      width: 60%;
      height: 30vw;
    }
    &--4 {
      top: 50%;
      right: -14%;
      width: 35vw;
      height: 35vw;
      z-index: 2;
    }
    &--101 {
      top: 25%;
      right: -5%;
      width: 30vw;
      height: 30vw;
      z-index: 2;
    }
    &--5 {
      top: 18%;
      left: -1%;
      width: 50%;
      height: 20vw;
    }
    &--6 {
      z-index: 2;
      bottom: 11%;
      right: -4%;
      width: 42%;
      height: 42%;
    }
    &--7 {
      width: 40%;
      // height: 70%;
      // mix-blend-mode: multiply;
      // opacity: 0.8;

      &--1 {
        mix-blend-mode: multiply;
        z-index: 2;
        left: -27%;
        top: 0;
      }

      &--2 {
        left: -15vw;
        top: 0;
      }
    }
    &--8 {
      bottom: 0%;
      right: 0;
      width: 30%;
    }
    &--9 {
      left: -7%;
      width: 45%;
      height: auto;
      z-index: 2;
      bottom: -13%;
    }

    &--10 {
      left: 0;
      bottom: 0%;
      width: 60%;
      height: auto;
      // z-index: 2;
    }
    &--11 {
      left: 0;
      bottom: -8%;
      width: 30%;
      height: auto;
      z-index: 2;
    }
  }

  &--building {
    .svg--5 {
      top: 3% !important;
    }

    .preview {
      background-color: #cc833d !important;
      margin-top: 20vw !important;
      // z-index: -1;

      &::before {
        background-color: #cc833d !important;
      }
    }

    .middle__caption {
      background-color: #cc833d !important;
      transform: translateY(15%);

      .--caption {
        // margin-top: 100vw;
        transform: translateY(20%);
      }
    }

    .related {
      background-color: #cc833d !important;
    }
  }

  &--property-development {
    .svg--5 {
      top: 4% !important;
    }

    .preview {
      background-color: #00b7aa !important;
      margin-top: 20vw !important;

      &::before {
        background-color: #00b7aa !important;
      }
    }

    .middle__caption {
      background-color: #00b7aa !important;
      transform: translateY(25%);

      &--caption {
        // margin-top: 100vw;
      }
    }

    .related {
      background-color: #00b7aa !important;
    }
  }

  section {
    position: relative;
  }

  .--bg-1 {
    min-height: 50vw;
    // padding: 15vw 0 0 0;
    padding-top: 15vw;
    .--max {
      //   max-width: 80%;
      //   min-height: 75.5vw;
    }
    .--project-details {
      width: 100%;
      position: relative;
      img {
        width: 100% !important;
        margin: 0;
      }
    }
    .--about-content {
      max-width: 58vw;
      margin: 3vw auto 5vw auto;
      display: block;
    }
  }

  .middle {
    $padding-bottom: 8vw;
    margin-top: -12vh;
    display: grid;

    p {
      margin: 0;
    }

    & > * {
      grid-row: 1/2;
      grid-column: 1/2;
    }

    &--tropicana-cenang {
      // background-color: #cc833d !important;
      padding-bottom: 15vw;
      background-color: #cc833d !important;

      &--1 {
        .middle__polygon-image {
          padding-bottom: 0;
        }
      }

      &--2 {
        margin-top: unset;

        .middle__caption {
          height: 80% !important;
        }
      }

      .svg--5 {
        top: 0;
        left: 0;
        width: 35%;
        height: auto !important;
      }

      .svg--6 {
        top: 36%;
        right: 0;
        width: 28%;
        height: auto !important;
        mix-blend-mode: darken;
      }
    }

    &__polygon-image {
      display: flex;
      justify-content: center;
      z-index: 1;
      padding-bottom: $padding-bottom;

      img {
        width: 100%;
      }
    }
    &__caption {
      font-family: "gill sans", sans-serif !important;
      white-space: pre-line;

      padding: 0 5vw 0 10vw;
      height: 60%;
      margin-top: auto;

      //   padding-bottom: 5vw;
      //   background-color: #00b7aa;

      display: flex;
      justify-content: end;
      align-items: flex-end;

      p {
        width: 50%;
        height: 20vw;
        font-size: 2.7vw;
        color: white;
        letter-spacing: 0.2em;
        line-height: 2em;
        font-family: "gill sans medium", sans-serif !important;
      }
    }
  }

  .preview {
    // background-color: #00b7aa;
    padding-bottom: 10vw;
    margin-top: -1px;

    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: 99%;
      width: 100%;
      height: 16vw;
      z-index: -1;
    }
  }

  .related {
    margin-top: -1px;
    // z-index: -1;
    // background-color: #00b7aa;
    padding: 30vw 0 13vw;

    .section-label {
      font-family: "gill sans", sans-serif !important;
      text-transform: uppercase;
      font-size: 1.2vw;
      letter-spacing: 0.2em;
      color: white;
      text-align: center;
      font-size: 4vw;
      margin-top: -5vw;
      margin-bottom: 10vw;
    }

    &__cards {
      margin-top: 5vw;
      display: flex;
      justify-content: center;
      align-items: center;

      & > * {
        width: 48%;
      }

      .preview-card {
        width: 100%;
        height: 32vw;
      }
    }
  }

  @media (max-width: 992px) {
    background-size: cover !important;
    .--bg-1 {
      padding: 230px 0 0 0 !important;
      .--max {
        max-width: 90% !important;
        .col-md-7,
        .col-md-5 {
          width: 100%;
          max-width: 100%;
        }
      }
      .--project-details {
        img {
          width: 100% !important;
          margin: 0 auto;
          object-fit: contain;
        }
      }
      .--about-content {
        max-width: 100%;
        margin: 30px auto !important;
        h3 {
          font-size: 3.6vw !important;
          text-align: center !important;
          @supports (-webkit-touch-callout: none) {
            letter-spacing: 0.15em;
            font-size: 3.5vw !important;
          }

          br {
            display: none;
          }
        }
        hr {
          width: 100%;
        }
        p {
          font-size: 0.875em !important;
          text-align: center !important;
          br {
            display: none;
          }
        }
        .col-md-6 {
          &:nth-child(2) {
            div {
              margin-left: 0 !important;
              margin-top: 50px;
              h3 {
                text-align: left !important;
                // font-size: 0.875em !important;
              }
              p {
                text-align: left !important;
                font-size: 0.875em !important;
                br {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
