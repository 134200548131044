/*
*
|  Page Name       : teamleaders.scss
|  Description     : scss for team leaders page use
|  Created by      : May Altamerano
|  Date Created    : July 1 2021
|  Last Update by  : May Altamerano
|  Last update     : July 1 2021
*
*/

.--team-leaders {
  overflow-x: hidden;

  .svg {
    position: absolute;

    &--1 {
      left: -4%;
      top: 19%;
      width: 42vw;
    }

    &--2 {
      right: -2%;
      top: 4.5%;
      width: 17vw;
    }

    &--3 {
      width: 100vw;
      z-index: -1;
      top: -50%;
    }

    &--4 {
      right: 0;
      top: -20%;
      width: 45vw; 
    }
  }

  .--title {
    font-family: "gill sans", sans-serif !important;
    text-transform: uppercase;
    color: #283a97;
    font-size: 5vw;
    letter-spacing: 0.2em;
  }

  .--desc {
    margin-top: 9vw;
    font-family: "gill sans medium", sans-serif !important;
    color: #283a97;
    font-size: 3vw;
    letter-spacing: 0.05em;
    line-height: 2em;
    color: #676ab1;
    white-space: pre-line;
  }

  .--sub-title {
    margin-top: 5vw;
    font-family: "gill sans medium", sans-serif !important;
    color: #283a97;
    font-size: 3.8vw;
    letter-spacing: 0.1em;
    line-height: 1.5em;
    color: #676ab1;
    white-space: pre-line;
    text-transform: uppercase;
  }

  .header {
    padding: 23vw 0 35vw;
  }

  .team-leader {
    background-color: #9192c2;
    border: 0;
    border-radius: 0;
    font-size: 3vw;
    display: flex;
    flex-direction: column;
    width: 83%;
    margin: 0 auto;

    padding: 7% 6%;

    hr {
      width: 20%;
      opacity: 1;
    }

    &__container {
      &:not(:first-child) {
        margin-top: 8vw; 
      }
    }

    &__link {
      text-decoration: none;
    }

    &__name {
      font-family: "gill sans", sans-serif !important;
      white-space: pre-line;
      line-height: 1.5em;
      letter-spacing: 0.2em;
      font-size: 1.05em;
    }

    &__position {
      font-family: "gill sans medium", sans-serif !important;
      white-space: pre-line;

      line-height: 1.5em;
      letter-spacing: 0.2em;
    }

    &__sub-position {
      font-family: "gill sans medium", sans-serif !important;
      line-height: 0;
      letter-spacing: 0.2em;
      font-size: 0.7em;
      text-align: end;
    }
  }

  .join-our-team {
    margin-top: 50vw;
    padding-bottom: 20vw;

    .join-image {
      &__image {
        width: 50%;
      }
    }

    .--title {
      margin-top: 10vw;
      font-size: 4.2vw;
      color: #676ab1;
    }

    .--desc {
      font-size: 3vw;
      letter-spacing: 0.05em;
    }

    .--btn-send-resume {
      background: #283a97;
      display: flex;
      border-radius: 0;
      border: 0;
      margin-top: 10vw;

      a {
        color: white;
        text-decoration: none;
        font-family: "gill sans", sans-serif !important;
        text-transform: uppercase;
        font-size: 3.3vw;
        letter-spacing: 0.2em;
        line-height: 2em;

        padding: 0.8em 13vw;
      }
    }
  }

  @media (max-width: 1024px) {
    .svg {
      position: absolute;
  
      &--1 {
        left: -4%;
        top: 20%;
        width: 42vw;
      }

      &--4 {
        right: -10%;
        top: -20%;
        width: 45vw; 
      }
    }

    .join-our-team {
  
      .--btn-send-resume {
        background: #283a97;
        display: flex;
        border-radius: 0;
        border: 0;
        margin-top: 10vw;
  
        a {
          color: white;
          text-decoration: none;
          font-family: "gill sans", sans-serif !important;
          text-transform: uppercase;
          font-size: 2vw;
          letter-spacing: 0.2em;
          line-height: 2em;
  
          padding: 0.8em 13vw;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .svg {
      position: absolute;
  
      &--1 {
        left: -4%;
        top: 19%;
        width: 42vw;
      }
  
    }
  }
}
