/*
*
|  Page Name       : aboutus.scss
|  Description     : scss for about us page use
|  Created by      : May Altamerano
|  Date Created    : June 29 2021
|  Last Update by  : May Altamerano
|  Last update     : July 1 2021
*
*/
.--about {
  width: 100%;
  position: relative;
  overflow-x: hidden;

  .svg {
    position: absolute;

    &--2 {
      bottom: 0;
      right: -12%;
      width: 100%;
    }

    &--3 {
      top: 0;
      left: 0;
      width: 100%;
    }

    &--4 {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;

      &:nth-of-type(1) {
        margin-bottom: -1px;
      }

      &:nth-of-type(2) {
        transform: rotateZ(180deg);
        margin-top: -1px;
      }
    }

    &--5 {
      mix-blend-mode: multiply;

      &:nth-of-type(1) {
        top: 0;
        right: 0;
        width: 90vw;
      }

      &:nth-of-type(2) {
        bottom: 0;
        left: 0;
        width: 98vw;

        // transform: rotateZ(180deg);
      }
    }
  }

  .splide {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // width: 1140px;
    margin: 0 auto;
    .splide__track {
      position: relative;
      // display: flex;
      width: 100%;
      img {
        // width: 66%;
        width: 75vw;
        aspect-ratio: 1/1;
        // height: 40vw;
        object-fit: cover;
        object-position: center;
      } 
      .splide__list {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .splide__slide {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
      }
    }
  }
  .splide__arrows {
    display: none;
  }
  .splide__pagination {
    position: relative;
    left: unset;
    transform: unset;
    margin-top: 5vw;
    @media not all and (min-resolution: 0.001dpcm) {
      bottom: 7vw !important;
    }
    li {
      margin: 0 1.1vw;
    }
    .splide__pagination__page {
      height: 3vw;
      width: 3vw;
      background-color: transparent !important;
      border: 1px solid #ffffff !important;
      &.is-active {
        transform: none !important;
        background-color: #ffffff !important;
      }
    }
  }
  .--right-container {
    position: relative;
    left: 0;
    width: 100%;

    margin-top: 5vw;

    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    hr {
      height: 1px;
      background-color: white;
      width: 60%;
      left: 0;
      opacity: 1;
    }
    h5 {
      &.--title {
        font-family: "gill sans", sans-serif !important;
        letter-spacing: 0.2em;
        font-weight: 500 !important;
        text-transform: uppercase;
        font-size: 4.4vw;
        color: #ffffff;
        margin: 0;
        text-align: center;
      }
    }
    p {
      &.--desc {
        font-family: "gill sans light", sans-serif !important;
        letter-spacing: 0.2em;
        font-weight: 400 !important;
        text-transform: uppercase;
        line-height: 2.3em;
        font-size: 4.4vw;
        color: #ffffff;
        white-space: pre-line;

        text-align: center;
      }
    }
  }

  .--header-container {
    .section-title {
      position: absolute;
      top: 22vw;
      left: 50%;
      transform: translateX(-50%);

      font-family: "gill sans", sans-serif !important;
      text-transform: uppercase;
      font-size: 4.8vw;
      letter-spacing: 0.1em;
      color: #283a97;
    }

    .our-story {
      min-height: 200vw;
      height: 100vh;
      position: relative;
    }

    .carousel {
      height: 100%;
      // background: #676ab1;

      .carousel-control-prev,
      .carousel-control-next {
        display: none !important;
      }
    }

    .carousel-indicators {
      width: 15vw;
      display: flex;
      justify-content: space-between;
      margin: 5vw auto 0;

      position: relative;
      top: unset;
      left: unset;
      bottom: unset;
      right: unset;
      padding-left: 0;
      display: none;

      &.custom {
        display: flex;
      }
      li {
        width: 4vw;
        aspect-ratio: 1/1;
        border-radius: 100px;
        border: 1px solid #283a97;
        background-color: transparent;
        transition: background-color 0.5s;

        :hover {
          background-color: #283a97;
          transition: background-color 0.5s;
        }
        &.active {
          background-color: #283a97;
          transition: background-color 0.5s;
        }
      }
    }

    .carousel-inner {
      height: 100%;
    }

    .carousel-item {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      opacity: 0;

      transition: opacity 0.2s ease-in-out;

      &--0 {
        .background {
          transform: translate(-29%, -8%);
          height: 110%;
          opacity: 0.4;
        }
      }

      &--1 {
        .background {
          height: 350vw;
          transform: translate(-340vw, -128vw);
          opacity: 0.4;
        }
      }

      &.true {
        opacity: 1;
        // height: 100%;
      }

      .background {
        position: absolute;
        top: 0;
        left: 0;

        // height: 100%;
      }

      .carousel-caption {
        width: 90%;
        right: unset;
        left: unset;
        bottom: unset;

        display: flex;
        flex-direction: column;

        padding: 35vw 0 30vw;
      }

      .description {
        white-space: pre-line;
        font-family: "gill sans medium", sans-serif !important;
        font-size: 2.7vw;
        line-height: 2.5em;
        letter-spacing: 0.05em;
        color: #676ab1;
        margin: 0;
        text-transform: uppercase;

        &:not(:last-child) {
          margin-bottom: 1.5em;
        }
      }

      .features {
        display: grid;
        width: 60%;
        gap: 1vw 5vw;
        margin: 0 auto;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }

      .feature {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .feature-name {
          margin-top: 1em;

          line-height: 1.3em;
          letter-spacing: 0.1em;
          font-size: 2.2vw;
          white-space: pre-line;
          text-transform: uppercase;

          color: #676ab1;
          font-family: "gill sans", sans-serif !important;
        }

        img {
          width: 10vw;
        }
      }

      .grow-with-us {
        font-family: "gill sans", sans-serif !important;
        text-decoration: none;
        color: white;
        letter-spacing: 0.1em;
        font-size: 3.5vw;

        background-color: #283a97;
        padding: 1.3em 5em;
        width: fit-content;
        margin: 4vw auto 0;
      }
    }
  }
  .--container-2 {
    position: relative;
    height: 110vh;
    padding: 10vw 0;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      &.--title {
        font-family: "gill sans light", sans-serif !important;
        letter-spacing: 0.3em;
        font-weight: 600 !important;
        text-transform: uppercase;
        line-height: 1.5em;
        font-size: 5.7vw;
        color: #283a97;
        margin: 12vw 0 0 0;
        text-align: center;
        -webkit-text-stroke-width: 0.5px;
        -webkit-text-stroke-color: currentColor;

        @supports (-webkit-touch-callout: none) {
          -webkit-text-stroke-width: 1px;
        }
      }
    }
    h2 {
      &.--title {
        font-family: "gill sans", sans-serif !important;
        letter-spacing: 0.2em;
        font-weight: 600 !important;
        text-transform: uppercase;
        line-height: 3vw;
        font-size: 4.5vw;
        color: #283a97;
        margin: 5.5vw 0 0 0;
        text-align: center;
      }
    }
    p {
      &.--sub-title {
        font-family: "gill sans", sans-serif !important;
        letter-spacing: 0.2em;
        font-weight: 600 !important;
        text-transform: uppercase;
        line-height: 1.3em;
        font-size: 3.5vw;
        color: #676ab1;
        margin: 15vw 0 0 0;
        text-align: center;
      }
    }
  }
  .--container-3 {
    // height: 64vw;
    // padding: 10vw 0;
    position: relative;

    .container {
      background-color: #2c3991;
      height: 120vw;
      max-width: 100vw;
    }
    .vision-content {
      isolation: isolate;
      padding: 0 !important;
      transform: translateY(25%);
    }
    .vision-bg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70vw;
      height: auto;
      z-index: -1;
    }

    h2 {
      &.--title {
        font-family: "gill sans", sans-serif !important;
        letter-spacing: 0.4vw;
        font-weight: 600 !important;
        text-transform: uppercase;
        line-height: 3vw;
        font-size: 2.2vw;
        color: #ffffff;
        margin: 14vw 0 0 0;
        text-align: center;
      }
    }
    p {
      &.--sub-title {
        font-family: "gill sans", sans-serif !important;
        letter-spacing: 0.27em;
        font-weight: 600 !important;
        text-transform: uppercase;
        line-height: 1.7em;
        font-size: 3.4vw;
        color: #ffffff;
        margin: 5vw 0 0 0;
        text-align: center;
      }
    }
  }
  .--container-4 {
    height: 50vw;
    padding: 10vw 0;
    position: relative;
  }
  .--container-5 {
    // height: 50vw;
    padding: 0;
    position: relative;
    .--max {
      max-width: 75%;
    }
    .--bg-1 {
      background: url("../images/aboutus/About_BG-07.png") top left -3vw;
      image-rendering: -webkit-optimize-contrast;
      background-repeat: no-repeat;
      background-size: 76vw 55vw;
      transition: background 0.3s;
      background-attachment: scroll;
      height: 100vw;
      width: 100%;
      position: absolute;
      top: -17vw;
      &::before {
        content: "";
        background-color: #283997;
        position: absolute;
        top: 19vw;
        left: 0;
        width: 100%;
        height: 50vw;
      }
    }
    .--bg-2 {
      background: url("../images/aboutus/About_BG-07.png") top left -4vw;
      image-rendering: -webkit-optimize-contrast;
      background-repeat: no-repeat;
      background-size: 105vw 55vw;
      transition: background 0.3s;
      background-attachment: scroll;
      height: 100vw;
      width: 100%;
      position: absolute;
      bottom: -4vw;
      transform: scale(-1, -1);
    }
  }
  .--container-6 {
    height: 50vw;
    padding: 10vw 0;
    position: relative;
  }
  .--container-7 {
    // height: 50vw;
    padding: 0;
    position: relative;
    .--max {
      max-width: 73%;
    }
    .--bg-1 {
      background: url("../images/aboutus/About_BG-07.png") top left -2vw;
      image-rendering: -webkit-optimize-contrast;
      background-repeat: no-repeat;
      background-size: 60vw 32vw;
      transition: background 0.3s;
      background-attachment: scroll;
      height: 50vw;
      width: 100%;
      position: absolute;
      top: -10vw;
      &::before {
        content: "";
        background-color: #283997;
        position: absolute;
        top: 10vw;
        left: 0;
        width: 100%;
        height: 50vw;
      }
    }
    .--bg-2 {
      background: url("../images/aboutus/About_BG-07.png") top left;
      image-rendering: -webkit-optimize-contrast;
      background-repeat: no-repeat;
      background-size: 65vw 55vw;
      transition: background 0.3s;
      background-attachment: scroll;
      height: 100vw;
      width: 100%;
      position: absolute;
      bottom: -14vw;
      transform: scale(-1, -1);
    }
  }
  .--container-8 {
    background-image: url("../images/aboutus/wedig-bg.png");
    height: 155vw;
    // min-height: 667px;
    // min-height: 59vw;
    // padding: 10vw 0;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    .col {
      margin-top: -10vw;
    }

    .--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.6vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 3vw;
      font-size: 3.2vw;
      color: #283a97;
      margin: 8vw 0 0 0;
      text-align: center;
      transform: scale(1) !important;
    }
    .--desc {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.2vw;
      font-weight: 500 !important;
      text-transform: uppercase;
      line-height: 3.5vw;
      font-size: 1.3vw;
      color: #283a97;
      margin: 6vw 0 0 0;
      text-align: center;
    }
  }
  .overlay-shape1 {
    background: url("../images/aboutus/About_BG-Shapes-01.png") bottom 11vw left;
    background-repeat: no-repeat;
    background-size: 103vw auto;
    transition: background 0.3s;
    width: 100%;
    position: absolute;
    z-index: 1;
    height: 66vw;
    top: 0;
    pointer-events: none;
  }
  .vision-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;

    &::before {
      content: "";
      background: url("../images/aboutus/Group 107.png");
      background-position: 50% top;
      background-repeat: no-repeat;
      background-size: cover;
      transition: background 0.3s;
      width: 100%;
      position: absolute;
      z-index: -5;
      height: 110%;
      top: 0;
    }
  }
  .overlay-shape--1 {
    background-image: url("../images/aboutus/About_BG-Shapes-02 1.png");
    background-repeat: no-repeat;
    background-size: contain;
    transition: background 0.3s;
    position: absolute;
    z-index: -1;
    width: 28%;
    height: 25%;
    top: 0;
    left: 0;
    // opacity: 0.9;
    mix-blend-mode: multiply;
  }
  .overlay-shape--2 {
    background: url("../images/aboutus/About_BG-Shapes-02 2.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right bottom;
    transition: background 0.3s;
    position: absolute;
    z-index: -1;
    width: 28%;
    height: 25%;
    bottom: 3%;
    right: 0;
    // opacity: 0.9;
    mix-blend-mode: multiply;
  }
  .mission-bg {
    background: url("../images/aboutus/About_BG-03.png") top center;
    background-repeat: no-repeat;
    background-size: 118vw 70vw;
    transition: background 0.3s;
    width: 100%;
    position: absolute;
    z-index: -5;
    height: 68vw;
    top: -4vw;
  }
  .section4-bg {
    background: url("../images/aboutus/Group 555.png") top center;
    image-rendering: -webkit-optimize-contrast;
    background-repeat: no-repeat;
    background-size: 101vw auto;
    transition: background 0.3s;
    width: 100%;
    position: absolute;
    z-index: -5;
    height: 60vw;
    top: 0vw;
  }
  .ourvalues-bg {
    background: url("../images/aboutus/Group 555.png") top center;
    image-rendering: -webkit-optimize-contrast;
    background-repeat: no-repeat;
    background-size: 101vw auto;
    transition: background 0.3s;
    width: 100%;
    position: absolute;
    z-index: -5;
    height: 50vw;
    top: 0vw;
  }
  .section6-bg {
    background: url("../images/aboutus/About_BG-05.png") bottom center;
    image-rendering: -webkit-optimize-contrast;
    background-repeat: no-repeat;
    background-size: 101vw auto;
    transition: background 0.3s;
    width: 100%;
    position: absolute;
    z-index: -5;
    height: 50vw;
    top: 0vw;
    &::before {
      content: "";
      top: 0;
      right: 0;
      position: absolute;
      width: 0;
      height: 0;
      border-right: 50vw solid transparent;
      border-bottom: 20vw solid #283a97;
      transform: rotate(180deg);
    }
  }
  .wedig-bg {
    background-repeat: no-repeat;
    background-size: 99vw 59vw;
    transition: background 0.3s;
    background-attachment: scroll;
    background: url("../images/aboutus/About_BG-06.svg");
    background-position: center;
    background-size: cover;
    // image-rendering: -webkit-optimize-contrast;
    width: 100%;
    position: absolute;
    z-index: -5;
    // height: 59vw;
    height: 100%;
    top: 0vw;

    &::before {
      content: "";
      background: url("../images/aboutus/About_BG-09.svg");
      background-position: top right;
      background-size: contain;
      background-repeat: no-repeat;
      mix-blend-mode: multiply;

      position: absolute;
      top: 0;
      right: 0;
      width: 60%;
      height: 30%;
      // z-index: 9;
    }
  }
  .content {
    // background: url(https://vulchivijay.files.wordpress.com/2015/01//grid.png) repeat scroll 0 0;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    // z-index: -5 !important;
  }
  .parallax-bg {
    background: url("../../assets/images/aboutus/BG-ABOUT-01.png") repeat scroll
      0 0;
    background-size: 110vw 55vw;
    left: 0;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 1vh;
    width: 100%;
    background-repeat: no-repeat;
  }
  .parallax-bg2 {
    background: url("../../assets/images/aboutus/Group 212.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 172vh;
    width: 100%;
    background-repeat: no-repeat;
  }
  .--mob {
    display: none;
  }
  @media (max-width: 992px) {
    margin: 0;

    .--mob {
      display: block;
    }
    .--header-container {
    }
    .parallax-bg {
      background-size: auto 124vw;
    }
    .parallax-bg2 {
      background-size: auto 124vw;
    }
    .overlay-shape1 {
      height: 96vw;
    }
    .vision-bg {
      // background-size: 100vw 700px;
      /* background-size: cover !important;
      height: 700px; */
    }
    .overlay-shape {
      background-size: 100vw 700px;
      height: 700px;
    }
    .--container-2 {
      /*  padding: 100px 0 !important;
      height: 700px !important;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center; */
      h2 {
        &.--title {
          // font-size: 5.5vw;
          // margin-top: 0;
        }
      }
      p {
        &.--sub-title {
          /*  font-size: 5.5vw;
          line-height: 1.2;
          margin: 50px 0 0 0; */
        }
      }
      /* h1 {
        &.--title {
          margin: 50px 0 0 0;
          font-size: 7vw;
          letter-spacing: 0.1em;
        }
      } */
    }
    .mission-bg {
      height: 700px;
      background-size: 150vw 700px;
      top: 0 !important;
    }
    .--container-3 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        &.--img {
          left: 8vw;
          top: 49vw; 
        }
      }
      h2 {
        &.--title {
          margin: 0;
          font-size: 4.8vw;
          line-height: initial;
        }
      }
      p {
        &.--sub-title {
          /* margin-top: 50px;
          font-size: 4.1vw; */
        }
      }
    }
    .--container-5 {
      // height: fit-content;
      background: #283997;
      transition: background 0.3s;
      background-attachment: scroll;
      .--mob {
        .--title {
          font-size: 4.8vw;
        }

        img {
          width: 110vw;
          margin-left: -8vw;
        }
      }

      .--max {
        max-width: 90%;

        .--right-container {
          /* .--title {
            font-size: 4.8vw;
          }

          .--desc {
            font-size: 4.8vw;
          } */
        }
      }
      .--bg-1,
      .--bg-2 {
        display: none;
      }
      .--title {
        margin: 0;
        font-size: 4vw;
        letter-spacing: 0.2em;
        line-height: 1.8em;
        font-family: "gill sans", sans-serif !important;
        text-transform: uppercase;
        color: #ffffff;
        text-align: center;
        white-space: pre;
      }
    }
    .--container-7 {
      margin-top: -1px;
      background: #283997;
      transition: background 0.3s;
      background-attachment: scroll;
      padding-top: 25vw;
      padding-bottom: 25vw;
      .--max {
        max-width: 100%;
        margin-top: -15vw;

        .--right-container {
          /* .--title {
            font-size: 4.8vw;
          }

          .--desc {
            font-size: 4.8vw;
            white-space: pre-line;
          } */
        }
      }
      h1 {
        &.--title {
          // margin-bottom: 18vw;
          font-size: 4.8vw;
        }
      }
      .--mob {
        display: block;
      }
      .--bg-1 {
        display: none;
      }
      .--title {
        margin: 0;
        font-size: 4vw;
        letter-spacing: 0.2em;
        line-height: 1.8em;
        font-family: "gill sans", sans-serif !important;
        text-transform: uppercase;
        color: #ffffff;
        text-align: center;
      }
    }
    .wedig-bg {
      height: 85vw;
    }
    .--container-8 {
      // height: 600px;

      // padding: 100px 0;
      margin-top: -1px;
      .--title {
        font-size: 4.8vw;
        margin-bottom: 50px;
      }
      .--desc {
        font-size: 2.7vw;
        line-height: 2.5 !important;
        /* br {
          display: none;
        } */ 
      }
      .wedig-bg {
        height: 600px;
        background-size: 140vw 100%;
      }
    }
  }
  @media (max-width: 880px) {
  }
  @media (max-width: 800px) {
  }
  @media (max-width: 900px) {
    .overlay-shape1 {
      background: url("../images/aboutus/About_BG-Shapes-01.png") bottom 0 left;
      background-size: 185vw 70vw;
      background-repeat: no-repeat;
      height: 200vw;
    }
    .parallax-bg {
      background-position: 0 -22vw !important;
    }
    .parallax-bg2 {
      background-position: 0 -22vw !important;
    }
    .--header-container {
      // height: 100vw !important;
      width: 100vw;

      .carousel-item {
        .description {
          white-space: pre-line;
          font-family: "gill sans medium", sans-serif !important;
          font-size: 2vw;
          line-height: 2.5em;
          letter-spacing: 0.05em;
          color: #676ab1;
          margin: 0;
          text-transform: uppercase;
  
          &:not(:last-child) {
            margin-bottom: 1.5em;
          }
        }

        .grow-with-us {
          font-family: "gill sans", sans-serif !important;
          text-decoration: none;
          color: white;
          letter-spacing: 0.1em;
          font-size: 2.5vw;
  
          background-color: #283a97;
          padding: 1.3em 5em;
          width: fit-content;
          margin: 4vw auto 0;
        }
      }
    }

    .--container-2 {
      position: relative;
      height: 110vh;
      padding: 10vw 0;
  
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      h1 {
        &.--title {
          font-family: "gill sans light", sans-serif !important;
          letter-spacing: 0.3em;
          font-weight: 600 !important;
          text-transform: uppercase;
          line-height: 1.5em;
          font-size: 5vw;
          color: #283a97;
          margin: 12vw 0 0 0;
          text-align: center;
        }
      }
      h2 {
        &.--title {
          font-family: "gill sans", sans-serif !important;
          letter-spacing: 0.2em;
          font-weight: 600 !important;
          text-transform: uppercase;
          line-height: 3vw;
          font-size: 4.8vw;
          color: #283a97;
          margin: 5.5vw 0 0 0;
          text-align: center;
        }
      }
      p {
        &.--sub-title {
          font-family: "gill sans", sans-serif !important;
          letter-spacing: 0.2em;
          font-weight: 600 !important;
          text-transform: uppercase;
          line-height: 1.3em;
          font-size: 4vw;
          color: #676ab1;
          margin: 15vw 0 0 0;
          text-align: center;
        }
      }
    }

    .--container-8 {
      background-image: url("../images/aboutus/wedig-bg.png");
      height: 80vh;
      // min-height: 667px;
      // min-height: 59vw;
      // padding: 10vw 0;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      .--title {
        font-family: "gill sans", sans-serif !important;
        letter-spacing: 0.6vw;
        font-weight: 600 !important;
        text-transform: uppercase;
        line-height: 3vw;
        font-size: 3.2vw;
        color: #283a97;
        margin: 8vw 0 0 0;
        text-align: center;
        transform: scale(1) !important;
      }
      .--desc {
        font-family: "gill sans", sans-serif !important;
        letter-spacing: 0.2vw;
        font-weight: 500 !important;
        text-transform: uppercase;
        line-height: 3.5vw;
        font-size: 2.5vw;
        color: #283a97;
        margin: 6vw 0 0 0;
        text-align: center;
      }
    }

    // .--container-7 {
    //   padding: 0;
    //   position: relative;
    //   .--max {
    //     max-width: 73%;

    //     .--desc {

    //     }
    //   }
    //   .--bg-1 {
    //     background: url("../images/aboutus/About_BG-07.png") top left -2vw;
    //     image-rendering: -webkit-optimize-contrast;
    //     background-repeat: no-repeat;
    //     background-size: 60vw 32vw;
    //     transition: background 0.3s;
    //     background-attachment: scroll;
    //     height: 50vw;
    //     width: 100%;
    //     position: absolute;
    //     top: -10vw;
    //     &::before {
    //       content: "";
    //       background-color: #283997;
    //       position: absolute;
    //       top: 10vw;
    //       left: 0;
    //       width: 100%;
    //       height: 50vw;
    //     }
    //   }
    //   .--bg-2 {
    //     background: url("../images/aboutus/About_BG-07.png") top left;
    //     image-rendering: -webkit-optimize-contrast;
    //     background-repeat: no-repeat;
    //     background-size: 65vw 55vw;
    //     transition: background 0.3s;
    //     background-attachment: scroll;
    //     height: 100vw;
    //     width: 100%;
    //     position: absolute;
    //     bottom: -14vw;
    //     transform: scale(-1, -1);
    //   }
    // }

    .--right-container {
      position: relative;
      left: 0;
      width: 100%;
  
      margin-top: 5vw;
  
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      hr {
        height: 1px;
        background-color: white;
        width: 60%;
        left: 0;
        opacity: 1;
      }
      h5 {
        &.--title {
          font-family: "gill sans", sans-serif !important;
          letter-spacing: 0.2em;
          font-weight: 500 !important;
          text-transform: uppercase;
          font-size: 4.4vw;
          color: #ffffff;
          margin: 0;
          text-align: center;
        }
      }
      p {
        &.--desc {
          font-family: "gill sans light", sans-serif !important;
          letter-spacing: 0.2em;
          font-weight: 400 !important;
          text-transform: uppercase;
          line-height: 2.3em;
          font-size: 3vw;
          color: #ffffff;
          white-space: pre-line;
  
          text-align: center;
        }
      }
    }

    
  }
  @media (max-width: 700px) {
    .--header-container {
      // height: 120vw !important;
    }
    .parallax-bg {
      background-size: auto 145vw;
    }
    .parallax-bg2 {
      background-size: auto 145vw;
    }
    .overlay-shape1 {
      height: 120vw;
    }
    .slideshow input {
      left: 22vw !important;
      bottom: 25vw !important;
      + label {
        left: 22vw !important;
        bottom: 25vw !important;
      }
    }
    .--header-container {
    }
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @media (max-width: 590px) {
      .slider {
        height: 100% !important;
        @media not all and (min-resolution: 0.001dpcm) {
          height: 100%;
        }
      }
    }
  }
  @media (max-width: 590px) {
    .overlay-shape--1 {
      width: 80%;
      height: 30%;
      left: -15%;
      top: -10%;
    }
    .overlay-shape--2 {
      width: 65%;
      height: 40%;
      right: -5%;
    }
    .--header-container {
    }
    .overlay-shape {
      background-size: 100vw 500px;
      height: 500px;
    }
    .vision-bg {
      /* background-size: 100vw 500px;
      height: 100%; */
    }
    .--container-2 {
      /* padding: 100px 0 !important;
      height: 130vh !important; */
    }
    .--container-3 {
      // height: 100vh !important;
      img {
        &.--img {
          top: 27vw;
        }
      }
    }
    .mission-bg {
      height: 500px;
      background-size: 150vw 500px;
    }
    .overlay-shape1 {
      height: 100%;
    }
    .parallax-bg {
      background-size: auto 135vh;
      background-position: -70vw -50vw !important;
    }
    .parallax-bg2 {
      background-size: auto 135vh;
      background-position: -70vw -50vw !important;
    }
    .--container-5 {
      // height: 650px;
      background-size: 100vw 650px;
    }
    .--container-7 {
      // height: 650px;
      // background-size: 100vw 650px;
      // .--bg-1 {
      //   .--title {
      //     font-size: 100vw;
      //   }
      // }
    }
  }
}
